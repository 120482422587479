import { Component, OnInit } from '@angular/core';
import { Router, Route, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/providers/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-password-request',
    templateUrl: './password-request.component.html',
    styleUrls: ['./password-request.component.scss'],
    standalone: true,
    imports: [FormsModule, NgClass]
})
export class PasswordRequestComponent implements OnInit {

  siteurl = 'i-Sms.pro';
  siteicon = './assets/img/i-sms-logo-transparent.png';

  isAdmin = '0';

  etape = 1;
  username = '';
  code = '';
  oldpassword = '';
  newpassword1 = '';
  newpassword2 = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService,
    private toastr: ToastrService,
  ) {
    const currentUrl = this.router.url;
    this.isAdmin = this.activatedRoute.snapshot.params.isAdmin;
    this.username = this.activatedRoute.snapshot.params.userName;
    if (this.username === '-') {
      this.username = '';
    }

    console.log(this.isAdmin, this.username);
  }

  ngOnInit(
  ) {
    this.etape = 1;
  }

  pwdBackLogin() {
    this.etape = 1;
    this.username = '';
    this.code = '';
    this.newpassword1 = '';
    this.newpassword2 = '';

    if (this.isAdmin === '0') {
      this.router.navigate(['/login']);
    } else {
      this.router.navigate(['/admin']);
    }
  }

  pwdRequestCode() {

    this.authService.requestCodeMail(this.username, this.isAdmin).subscribe( (response: any) => {
      if (response.success) {
        this.etape = 2;
      } else {
        if (response.message === 'unknown_client') {
          this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
        }
      }
    });
  }

  pwdCheckCode() {
    this.authService.checkCode(this.username, this.code, this.isAdmin).subscribe((response: any) => {
      if (response.success) {
        this.etape = 3;
      } else {
          if (response.message === 'unknown_client') {
            this.toastr.error('Email inconnu !', 'Erreur', { timeOut: 0 });
          } else if (response.message === 'invalid_code') {
            this.toastr.error('Le code ne correspond pas !', 'Erreur', { timeOut: 0 });
          }
      }
    });
  }

  pwdChange() {
    this.authService.changePassword(this.username, this.code, this.newpassword1, this.newpassword2, this.isAdmin).subscribe((response: any) => {
      if (response.success) {
        this.toastr.clear();
        this.pwdBackLogin();
      } else {
        if (response.message === 'unknown_client') {
          this.toastr.error('Email inconnu !', 'Erreur', { timeOut: 0 });
          this.pwdBackLogin();
        }
        if (response.message === 'invalid_code') {
          this.toastr.error('Le code ne correspond pas !', 'Erreur', { timeOut: 0 });
          this.etape = 2;
        }
        if (response.message === 'password_dont_match') {
          this.toastr.error('Les mots de passe ne correspondent pas !', 'Erreur', { timeOut: 0 });
        }
      }

    });
  }

}
