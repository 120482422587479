<div class="main-container">

  <div class="header">
    <a class="navbar-brand" href="#/">
      <img [src]="siteicon" height="50" />
    </a>
    <!-- <h3 style="margin-top: 13px;">{{siteurl}}</h3> -->
  </div>

  <div class="" style="margin-top:15%">
    <div id="loginbox" class="mainbox col-sm-8 offset-sm-2 col-xs-12  admin-block p-2">

      <div class="card card-block p-4">
        <div class="card-title">
          <h2>Bienvenue admin!</h2>
        </div>

        <div class="card-block">

          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="col">
              <div class="form-group">
                <label for="username">Email</label>
                <input type="text" formControlName="username" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                @if (submitted && f.username.errors) {
<div class="invalid-feedback">
                  @if (f.username.errors.required) {
<div>Champ obligatoire</div>
}
                </div>
}
              </div>
              <div class="form-group">
                <label for="password">Mot de passe</label>
                <input type="password" formControlName="password" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                @if (submitted && f.password.errors) {
<div class="invalid-feedback">
                  @if (f.password.errors.required) {
<div>Champ obligatoire</div>
}
                </div>
}
              </div>
            </div>

            @if (error=='unknown_admin') {
<div class="alert alert-danger">Ce compte n'existe pas</div>
}
            @if (error=='incorrect_password') {
<div class="alert alert-danger">Erreur d'authentification</div>
}

            <div style="clear:both;"></div>
            <div class="form-actions">
              <button type="submit" class="btn btn-success">
                Se connecter
              </button>
              <div class="btn btn-link" (click)="passwordRequest()">Mot de passe oublié</div>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>

  <div class="hiddenbr" [routerLink]="['/login']">&nbsp;</div>

</div>
