
<app-spinner [visible]="isSpinnerVisible"></app-spinner>


<div class="dropzone-custom" [ngClass]="{'small': isSmall}">
  <div class="dropzone-label">Glisser votre document ou cliquez ICI</div>
  <input type="file" accept="application/vnd.ms-excel.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv" (change)="onFilesAdded($event.target.files)" multiple="false" mode="replace"/>
</div>


<app-dialog [(isActive)]="dialog.show" [overlayDismiss]="true" (closeClick)="closeDialog()"
  [isLarge]="dialog.isLarge" [dialogTitle]="dialog.title" [dialogBody]="dialog.body" [dialogAction]="dialog.action" [dialogType]="dialog.type">
</app-dialog>

<app-modal [(isActive)]="showImportList" [overlayDismiss]="true" (overlayClick)="showImportList=false"
  [isXLarge]="true">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Création d'une liste</h5>
      <div class="close" data-dismiss="modal" aria-label="Close" (click)="showImportList=false">
        <span aria-hidden="true">&times;</span>
      </div>
    </div>

    <div class="modal-body">
      <form role="form" name="form">
        <div class="col-xs-12 mb-4">
          <label for="name" class="bold">Nom de la liste</label>
          <input type="text" name="name" id="name" class="form-control w-100" [(ngModel)]="listName" autocomplete="off"
            autocapitalize="off" autocorrect="off" required #nameRef>
          @if (nameRef.errors?.required) {
<div class="text text-danger">Saisissez un nom</div>
}
        </div>
        @if (output.length>0) {
<div class="col-xs-12 mb-4">
          <label for="sheetname" class="bold">Feuille Excel à importer</label>
          <div class="form-group">
            @for (sheet of output; track sheet; let i = $index) {
<div class="radio-group mr-2">
              <input type="radio" name="selectedSheet" id="checkboxes{{i}}" (change)="selectSheet(sheet)" [checked]="sheet.name == selectedSheet.name">&nbsp;{{sheet.name}}
            </div>
}
          </div>
        </div>
}
        @if (selectedSheet?.fields) {
<div class="col-xs-12 mb-4">
          <label for="mobile" class="bold">Champ téléphone</label>
          <select class="w-100 form-control" name="mobile" id="mobile" [(ngModel)]="mobile">
            <option value="" disabled hidden>Sélectionnez un champ</option>
            @for (f of output[selectedSheet?.id]?.fields; track f) {
<option [ngValue]="f">{{f}}</option>
}
          </select>
          @if (mobile=='') {
<div class="text-danger">Sélectionnez un champ</div>
}
        </div>
}
        @if (selectedSheet?.fields) {
<div>{{output[selectedSheet.id].data.length}} lignes à importer</div>
}
      </form>
    </div>

    <div class="modal-footer">
      <button class="btn btn-primary" (click)='confirmListImport()' [disabled]="mobile==''">Créer</button>
      <div class="btn btn-secondary" data-dismiss="modal" (click)="showImportList=false">Annuler</div>
    </div>
  </div>
</app-modal>
