export interface IAlias {
  id: number;
  customerid: number;
  label: string;
  destination_list: string;
}

export class Alias {
  id: number;
  customerid: number;
  label: string;
  destination_list: string;
  constructor(customerid, label, destination_list) {
    this.id = 0;
    this.customerid = customerid;
    this.label = label;
    this.destination_list = destination_list;
  }
}
