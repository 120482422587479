<!-- <h3>Nouvelle annonce:</h3> -->

@if (selectedAnnonce) {
<form name="form" role="form" autocomplete="off" autocapitalize="off" autocorrect="off"
  #f="ngForm">

  <div class="form-check col-12">
    <div class="row">
      <div class="col-12 col-sm-4">
        <label class="h5" for="date">Référence</label>
        <div class="input-group">
          <input type="text" class="form-control" id="refrence" name="refrence" [(ngModel)]="selectedAnnonce.reference"
            autocomplete="off" autocapitalize="off" placeholder="Référence" required readonly>
        </div>
      </div>
      <div class="col-6 col-sm-8">
        <label class="h5" for="annonce">Contact agence</label>
        <div class="input-group">
          <input type="text" class="form-control" id="contact" name="contact" [(ngModel)]="selectedAnnonce.contact"
            autocomplete="off" autocapitalize="off" placeholder="votre Email ou contact agence" required>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12">
    <label for="smstext">Modéle du texte SMS envoyé</label>
    <ul>
      <li>Ajouter %reference% pour ajouter la référence de l'annonce</li>
      <li>Ajouter %url% pour le lien long de l'annonce</li>
    </ul>
    <textarea name="smstext" id="smstext" class="form-control" style="resize:vertical;margin-bottom:10px;" rows="5"
      [(ngModel)]="selectedAnnonce.smstext"></textarea>
  </div>


  <div class="col-12">
    <div class="form-group">
      <label class="h5 control-label" for="reference">URL site web:</label>
      <div class="input-group">
        <input type="text" name="url" id="url" class="form-control" [(ngModel)]="selectedAnnonce.url" autocomplete="off"
          autocapitalize="off" autocorrect="off" placeholder="URL de la page WEB de l'annonce" required
          #urlRef="ngModel" />
        @if (urlRef.dirty && urlRef.errors?.required) {
<div class="text text-danger">Champ obligatoire</div>
}

        <div class="btn btn-success" (click)="loadPreviewAnnonce()">Vérifier</div>
      </div>
    </div>
  </div>
  <div class="form-actions d-flex">

    <button class="btn btn-danger" (click)="delete()">Supprimer l'annonce</button>

    <button [disabled]="f.invalid" class="btn btn-success ml-auto"
      (click)="modify()">Modifier l'annonce</button>

  </div>
</form>
}
<br>
<br>


<app-modal [(isActive)]="showDeleteConfirm" [overlayDismiss]="true" (overlayClick)="showDeleteConfirm=false">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Suppression d'une annonce</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showDeleteConfirm=false">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Confirmez-vous la suppression de cette annonce ?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)='deleteConfirm()'>Confirmer</button>
      <button type="button" class="btn btn-secondary" data-dismiss="modal"
        (click)="showDeleteConfirm=false">Annuler</button>
    </div>
  </div>
</app-modal>
