<div class="client-view p-md-4">

  @if (!client) {
  <app-client-select [selectedUser]="client"></app-client-select>
  } @else {

  <app-spinner [visible]="isSpinnerVisible"></app-spinner>

  <div class="card">
    <div class="card-body">

      <h4>Gestion des annonces</h4>

      <ul class="nav nav-pills mb-n3" id="myTab" role="tablist">
        <li class="nav-item">
          <div class="nav-link" [ngClass]="{'active': activeTab===0}" id="tab0" data-toggle="tab" (click)="selectTab(0)"
            role="tab" aria-controls="tous" aria-selected="true">Toutes</div>
        </li>
        <li class="nav-item">
          <div class="nav-link" [ngClass]="{'active': activeTab===1}" id="tab1" data-toggle="tab" (click)="selectTab(1)"
            role="tab" aria-controls="details" aria-selected="false">Demandes clients</div>
        </li>
        <li class="nav-item">
          <div class="nav-link" [ngClass]="{'active': activeTab===2}" id="tab2" data-toggle="tab" (click)="selectTab(2)"
            role="tab" aria-controls="nouveau" aria-selected="false">Nouvelle annonce</div>
        </li>
      </ul>
      <hr>

      <div class="tab-content" id="myTabContent">

        @if (activeTab === 0) {
        <div class="tab-pane fade show active" id="coord" role="tabpanel" aria-labelledby="coord-tab">

          <app-annonce-list [client]='client' (annonceSelect)="selectAnnonce($event)" (annonceEdit)="editAnnonce($event)">
          </app-annonce-list>

        </div>
        }
      </div>

      <div class="tab-content" id="myTabContent">

        @if (activeTab === 1) {
        <div class="tab-pane fade show active" id="coord" role="tabpanel" aria-labelledby="coord-tab" >

          <app-annonce-stat [client]='client' [annonce]="selectedAnnonce" (statAnnonce)="statAnnonce($event)">
          </app-annonce-stat>

        </div>
        }
      </div>

      <div class="tab-content" id="myTabContent">

        @if (activeTab === 2) {
        <div class="tab-pane fade show active" id="coord" role="tabpanel" aria-labelledby="coord-tab" >

          <!-- TAB annonce new -->
          <app-annonce-new [client]='client' (annonce)="displayAnnonces()">
          </app-annonce-new>

        </div>
        }
      </div>

      <div class="tab-content" id="myTabContent">

          @if (activeTab === 3) {
          <div class="tab-pane fade show active" id="coord" role="tabpanel" aria-labelledby="coord-tab" >

            <app-annonce-edit [client]='client' [selectedAnnonce]="selectedAnnonce" (annonce)="displayAnnonce($event)">
            </app-annonce-edit>

          </div>
          }
        </div>

    </div>
  </div>
  }
</div>

