<app-menu-header></app-menu-header>



<div class="client-view p-md-4">

  <app-spinner [visible]="isSpinnerVisible"></app-spinner>

  <div class="card">
    <div class="card-body">

      <h3>Liste des clients</h3>

      <div class="inline">
        <label>Créer un client ...</label>
        <div class="form-group">
          <button class="btn btn-success btn-sm" (click)="createClient()"><i class="fa fa-plus"></i></button>
        </div>
      </div>
      <br>

      <div class="clearfix">
        <form class="inline">
          <button type="button" (click)="Export('TEXT')"
            class="btn btn-sm btn-outline-secondary ml-2 h-100">Copy</button>
          <button type="button" (click)="Export('CSV')" class="btn btn-sm btn-outline-secondary ml-2 h-100">CSV</button>
          <button type="button" (click)="Export('EXCEL')"
            class="btn btn-sm btn-outline-secondary ml-2 h-100">Excel</button>
          <button type="button" (click)="Export('PRINT')"
            class="btn btn-sm btn-outline-secondary ml-2 h-100">Print</button>

          <div class="form-group form-inline ml-auto">
            Recherche: <input class="form-control ml-2" type="text" name="searchTerm"
              [ngModel]="pagingService.searchTerm"  (ngModelChange)="setSearchTerm($event)"/>
            <!-- <span class="ml-3" *ngIf="loading$ | async">Chargement...</span> -->
          </div>
        </form>

        <table id="selectedColumn" class="table table-bordered table-striped table-hover table-sm" cellspacing="0"
          width="100%">
          <thead>
            <tr>
              <th class="th" sortable="id" (sort)="onSort($event)">ID</th>
              <th class="th" sortable="username" (sort)="onSort($event)">Email</th>
              <th class="th collapsible" sortable="company" (sort)="onSort($event)">Société</th>
              <th class="th collapsible" sortable="firstname" (sort)="onSort($event)">Prénom</th>
              <th class="th collapsible" sortable="name" (sort)="onSort($event)">Nom</th>
              <th class="th collapsible" sortable="premium" (sort)="onSort($event)">#Premium</th>
              <th class="th collapsible" sortable="admin" (sort)="onSort($event)">Admin</th>
              <th class="th collapsible" sortable="premium" (sort)="onSort($event)">Suspendu</th>
              <th class="th collapsible" sortable="dolibarr_socid" (sort)="onSort($event)">N°Dolibarr</th>
              <th class="th">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            @for (user of users$ | async; track user) {
              <tr (click)="selectClient(user)" [ngClass]="{'selected': user.id === selectedUser?.id}">
              <td>{{user.id}}</td>
              <td>
                <ngb-highlight [result]="user.username" [term]="pagingService.searchTerm"></ngb-highlight>
              </td>
              <td class="collapsible">
                <ngb-highlight [result]="user.company" [term]="pagingService.searchTerm"></ngb-highlight>
              </td>
              <td class="collapsible">
                <ngb-highlight [result]="user.firstname" [term]="pagingService.searchTerm">
                </ngb-highlight>
              </td>
              <td class="collapsible">
                <ngb-highlight [result]="user.name" [term]="pagingService.searchTerm">
                </ngb-highlight>
              </td>
              <td class="collapsible">{{user.premium}}</td>
              <td class="collapsible">{{user.admin}}</td>
              <td class="collapsible">{{user.suspendu}}</td>
              <td class="collapsible">{{user.dolibarr_socid}}</td>
              <td>
                <div class="inline">
                  <button type="button" title="Editer" (click)="Edit(user)" class="btn btn-sm btn-primary ml-1 w30"><i
                      class="fa fa-edit"></i></button>
                  <button type="button" title="Supprimer" (click)="Delete(user)"
                    class="btn btn-sm btn-danger ml-1 w30"><i class="fa fa-trash-o"></i></button>
                  <button type="button" title="Campagnes" (click)="showCampaigns(user)"
                    class="btn btn-sm btn-success ml-1 w30"><i class="fa fa-play"></i></button>
                  <button type="button" title="Listes" (click)="showLists(user)"
                    class="btn btn-sm btn-warning ml-1 w30"><i class="fa fa-clone"></i></button>
                  <button type="button" title="Crédits" (click)="showCredits(user)"
                    class="btn btn-sm btn-info ml-1 w30"><i class="fa fa-info"></i></button>
                </div>
              </td>
            </tr>
            }
          </tbody>
        </table>

        <div class="inline">
          <div class="">
            Total: {{(total$ | async)}}
          </div>
          <div class="ml-auto">
            <ngb-pagination class="d-flex justify-content-end" [collectionSize]="total$ | async"
              [(page)]="pagingService.page" [boundaryLinks]="true" [rotate]="true" [directionLinks]="true"
              [pageSize]="pagingService.pageSize" [maxSize]="3" name="page">
            </ngb-pagination>
          </div>
        </div>

      </div>


      <h2>Email des clients</h2>
      <textarea id="usernameList" name="usernameList"
        style="width:100%; height:80px !important">{{usernameList}}</textarea>
      <div class="btn btn-primary" (click)="clipboardCopy()">
        <i class="fa fa-copy"></i>&nbsp;&nbsp;Copier dans le presse-papier
      </div>
      <div class="btn btn-success ml-2" (click)="mailUserNameList()">
        <i class="fa fa-envelope-o"></i>&nbsp;&nbsp;Envoyer par mail
      </div>
    </div>
  </div>
</div>

<app-modal [(isActive)]="showDeleteConfirm" [overlayDismiss]="true" (overlayClick)="showDeleteConfirm=false">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Suppression du compte</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showDeleteConfirm=false">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Confirmez-vous la suppression de ce compte ?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)='DeleteConfirm()'>Confirmer</button>
      <button type="button" class="btn btn-secondary" data-dismiss="modal"
        (click)="showDeleteConfirm=false">Annuler</button>
    </div>
  </div>
</app-modal>
