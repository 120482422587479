

import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/providers/user.service';
import { IUser } from 'src/app/interfaces/user';
import { AnnonceService } from 'src/app/providers/annonce.service';
import { CampaignService } from 'src/app/providers/campaign.service';

import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AnnonceEditComponent } from './annonce-edit.component';
import { AnnonceNewComponent } from './annonce-new.component';
import { AnnonceStatComponent } from './annonce-stat.component';
import { AnnonceListComponent } from './annonce-list.component';
import { SpinnerComponent } from '../spinner/spinner.component';
import { ClientSelectComponent } from '../client-select/client-select.component';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-annonce-menu',
    templateUrl: './annonce-menu.component.html',
    styleUrls: ['./annonce-menu.component.scss'],
    standalone: true,
    imports: [ClientSelectComponent, SpinnerComponent, NgClass, AnnonceListComponent, AnnonceStatComponent, AnnonceNewComponent, AnnonceEditComponent]
})
export class AnnonceMenuComponent implements OnInit {

  client: IUser;
  activeTab = 0;
  campaign: any;

  selectedAnnonce = null;
  isSpinnerVisible = false;

  constructor(
    private userService: UserService,
    private annonceService: AnnonceService,

  ) { }

  ngOnInit() {
    this.userService.selectedUser$.subscribe(user => {
      if (user) this.client = user;
    });
    this.displayAnnonces();
  }

  selectTab(tab) {
    if (this.activeTab !== tab) {
      this.activeTab = tab;
      return;
    }
    this.activeTab = -1;
    setTimeout(() => {
      this.activeTab = tab;
    }, 0);
  }

  displayAnnonce(annonce) {
    // TODO display annonce
  }

  displayAnnonces() {
    this.selectTab(0);  // display all annonces for this client
  }

  selectAnnonce(annonce) {
    console.log('selectAnnonce', annonce);
    this.selectedAnnonce = annonce;
    this.selectTab(1);   // display selected annonce details
  }

  editAnnonce(annonce) {
    console.log('editAnnonce', annonce);
    this.selectedAnnonce = annonce;
    this.selectTab(3);   // display selected annonce details
  }

  statAnnonce(annonce) {
    console.log('statAnnonce', annonce);
    // TODO
  }

}
