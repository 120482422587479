import { ViewChildren, QueryList, Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { Observable, ReplaySubject, of } from 'rxjs';
import { SortEvent, NgbdSortableHeader } from 'src/app/directives/sortable.directive';
import { environment } from '../../../environments/environment';

import { PagingService } from '../../providers/paging.service';
import { UserService } from 'src/app/providers/user.service';
import { Router } from '@angular/router';

import * as R from 'ramda';
import { ExportDataService } from 'src/app/providers/export-data.service';
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbHighlight, NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { IAlias, Alias } from 'src/app/interfaces/alias';
import { IUser } from 'src/app/interfaces/user';
import { ModalComponent } from '../../modals/modal.component';
import { NgClass, AsyncPipe } from '@angular/common';
import { NgbdSortableHeader as NgbdSortableHeader_1 } from '../../directives/sortable.directive';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-aliases',
    templateUrl: './aliases.component.html',
    styleUrls: ['./aliases.component.scss'],
    standalone: true,
    imports: [NgxSpinnerModule, FormsModule, NgbdSortableHeader_1, NgClass, NgbHighlight, NgbPagination, ModalComponent, AsyncPipe]
})
export class AliasesComponent implements OnInit {

  aliases$: Observable<IAlias[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;

  users: IAlias[] = [];
  selectedAlias: IAlias = new Alias(0, '', '');

  @Input() client: IUser;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  page = 1;
  pageSize = 10;
  collectionSize = 1;
  searchFields = ['id', 'label', 'message'];

  showDeleteConfirm = false;
  showAddAlias = false;
  showEditAlias = false;
  initialized = false;
  computedDefault = { long: 0, sms: 0 };
  computed = this.computedDefault;
  isSpinnerVisible = false;


  constructor(
    public pagingService: PagingService<IAlias>,
    private userService: UserService,
    private exportDataService: ExportDataService,

    private toastr: ToastrService,
    private router: Router,
    private modalService: NgbModal,
  ) {
    this.aliases$ = pagingService.rowsObs;
    this.total$ = pagingService.totalObs;
    this.loading$ = pagingService.loadingObs;
    this.pagingService.searchTerm = '';
    // set fields for filtering
    this.pagingService.fields = this.searchFields;


  }

  ngOnInit(): void {

    console.log('aliases onInit', this.client);
    this.pagingService.loadRows(of([]));
    if (this.client) {
      this.pagingService.loadRows(this.userService.GetAliases(this.client.id));
      this.loading$.subscribe(loading => {
        if (loading) {
          this.isSpinnerVisible = true;
        } else {
          this.isSpinnerVisible = false;
          this.initialized = true;
        }
      });
      this.pagingService.page = 1;
    }
  }

  pageChanged(event) {
    console.log('onPageChange', event);
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.pagingService.sortColumn = column;
    this.pagingService.sortDirection = direction;
  }

  selectAlias(alias) {
    this.selectedAlias = R.clone(alias);
  }



  Edit(alias) {
    this.selectedAlias = R.clone(alias);
    this.showEditAlias = true;
  }

  Add() {
    if (!this.client || this.client.id === 0) { return; }
    this.selectedAlias = new Alias(this.client.id, '', '');
    this.showEditAlias = true;
  }

  UpdateAlias() {
    this.isSpinnerVisible = true;

    // check mobiles list
    this.selectedAlias.destination_list = this.selectedAlias.destination_list.replace(/\s/g, '').replace(/^;/g, '').replace(/;$/g, '');
    if (this.selectedAlias.destination_list.length === 0) {
      this.toastr.warning('La liste des numéros est vide', 'Erreur', { timeOut: 3000 });
      return;
    }
    const mobiles = this.selectedAlias.destination_list.split(';').filter(i => i); // filter empty entries
    for (const mobile of mobiles) {
      let numero = mobile.replace(/\+33/, '0');
      if (numero.length !== 10 || numero.match(/^[0-9]+$/)===null || numero[0] !== '0' || (numero[1] !== '6' && numero[1] !== '7')) {
        this.toastr.warning('L\'un des numéros est invalide', 'Erreur', { timeOut: 3000 });
        return;
      }
    }
    this.selectedAlias.destination_list = mobiles.join(';');

    if (this.selectedAlias.id > 0) {
        this.userService.UpdateAlias(this.client.id, this.selectedAlias)
        .subscribe((response: any) => {
            if (response.success) {
                this.toastr.success('L\'alias \'' + this.selectedAlias.label + '\' a été modifié');
                this.pagingService.loadRows(this.userService.GetAliases(this.client.id));
                this.showEditAlias = false;
              } else {
              this.toastr.error(response.message, 'Erreur', { timeOut: 2000 });
            }
            this.isSpinnerVisible = false;
        });
    } else {
      this.userService.AddAlias(this.client.id, this.selectedAlias)
        .subscribe((response: any) => {
            if (response.success) {
              this.toastr.success('L\'alias \'' + this.selectedAlias.label + '\' a été ajouté');
              this.pagingService.loadRows(this.userService.GetAliases(this.client.id));
              this.showEditAlias = false;
            } else {
              this.toastr.error(response.message, 'Erreur', { timeOut: 2000 });
            }
            this.isSpinnerVisible = false;
        });
    }
  }

  // Export(kind) {
  //   console.log('export', kind);
  //   const records = [];
  //   const fields = ['id', 'label', 'destination_list'];
  //   for (const u of this.pagingService.filteredRows) {
  //     const model = R.pick(fields, u);
  //     records.push(model);
  //   }
  //   const header = {};
  //   fields.forEach(f => header[f] = f.replace(/[^a-z0-9]/gmi, '').toUpperCase());
  //   records.unshift(header);
  //   this.exportDataService.exportAs(kind, records, 'user');
  // }

  Delete(alias) {
    this.selectedAlias = alias;
    this.showDeleteConfirm = true;
    console.log('show delete confirm');
  }

  DeleteConfirm() {
    this.showDeleteConfirm = false;
    console.log('call delete api');
    this.isSpinnerVisible = true;

    this.userService.DeleteAlias(this.client.id, this.selectedAlias.id).subscribe((response: any) => {
      if (response.success) {
        this.toastr.success('L\'alias a été supprimé', '');
        this.pagingService.loadRows(this.userService.GetAliases(this.client.id));
      } else {
        this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
      }
      this.isSpinnerVisible = false;
    });
  }

}
