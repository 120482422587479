export interface IUser {
  id: number;
  address1: string;
  address2: string;
  admin: number;
  apikey: string;
  authemail: string;
  bic: string;
  city: string;
  company: string;
  datecreation: string;
  datemodification: string;
  deleted: string;
  disabled: string;
  dolibarr_ftc: number;
  dolibarr_socid: number;
  email_admin: string;
  email_invalid: number;
  firstname: string;
  forfait_firstday: string;
  forfait_period: number;
  forfait_quantity: number;
  fromname: string;
  iban: string;
  ipfrom: string;
  islimited: number;
  lowcost_ht: number;
  lowcost: number;
  lowcost_default?: number;
  name: string;
  option_forfait: number;
  option_lowcost: number;
  option_mail2sms: number;
  option_postpaid: number;
  option_sequence: number;
  option_mosms: number;
  password: string;
  phone1: string;
  phone2: string;
  postalcode: string;
  postpaid_period: number;
  premium_ht: number;
  premium: number;
  premium_default?: number;
  pwdcode: number;
  siret: string;
  tva: string;
  username: string;
  warn_below: number;
  adminphone: string;
  adminusername: string;
}

export class User implements IUser {
  // tslint:disable:variable-name
  id: number;
  address1: string;
  address2: string;
  admin: number;
  apikey: string;
  authemail: string;
  bic: string;
  city: string;
  company: string;
  datecreation: string;
  datemodification: string;
  deleted: string;
  disabled: string;
  dolibarr_ftc: number;
  dolibarr_socid: number;
  email_admin: string;
  email_invalid: number;
  firstname: string;
  forfait_firstday: string;
  forfait_period: number;
  forfait_quantity: number;
  fromname: string;
  iban: string;
  ipfrom: string;
  islimited: number;
  lowcost_ht: number;
  lowcost: number;
  lowcost_default?: number;
  name: string;
  option_forfait: number;
  option_lowcost: number;
  option_mail2sms: number;
  option_postpaid: number;
  option_sequence: number;
  option_mosms: number;
  password: string;
  phone1: string;
  phone2: string;
  postalcode: string;
  postpaid_period: number;
  premium_ht: number;
  premium: number;
  premium_default?: number;
  pwdcode: number;
  siret: string;
  tva: string;
  username: string;
  warn_below: number;
  adminphone: string;
  adminusername: string;

  // tslint:disable:variable-name
  constructor() {
    this.id = 0;
    this.address1 = '';
    this.address2 = '';
    this.admin = 0;
    this.apikey = '';
    this.authemail = '';
    this.bic = '';
    this.city = '';
    this.company = '';
    this.deleted = '';
    this.disabled = '';
    this.dolibarr_ftc = 0;
    this.dolibarr_socid = 0;
    this.email_admin = '';
    this.email_invalid = 0;
    this.firstname = '';
    this.forfait_firstday = '0000-00-00';
    this.forfait_period = 0;
    this.forfait_quantity = 0;
    this.fromname = '';
    this.iban = '';
    this.ipfrom = '';
    this.islimited = 0;
    this.lowcost_ht = 0;
    this.lowcost = 0;
    this.name = '';
    this.option_forfait = 0;
    this.option_lowcost = 0;
    this.option_mail2sms = 0;
    this.option_postpaid = 0;
    this.option_sequence = 0;
    this.option_mosms = 0;
    this.password = '';
    this.phone1 = '';
    this.phone2 = '';
    this.postalcode = '';
    this.postpaid_period = 1;
    this.premium_ht = 0;
    this.premium = 0;
    this.pwdcode = 0;
    this.siret = '';
    this.tva = '';
    this.username = '';
    this.warn_below = 20;
    this.adminphone = '';
    this.adminusername = '';

    }
}

