import { Component, OnInit, Input, EventEmitter, Output, Injectable } from '@angular/core';
import { IAdeFormation } from 'src/app/interfaces/ade';

import * as R from 'ramda';
import moment from 'moment';
import { NgbDatepickerI18n, NgbDateStruct, NgbDateAdapter, NgbDateNativeAdapter, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { AllianceDistributionService } from 'src/app/providers/alliance-distribution.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContactListService } from 'src/app/providers/contact-list.service';
import { IContactList } from 'src/app/interfaces/contact-list';
import { TranslationWidth, NgClass } from '@angular/common';
import { ImportFileComponent } from '../import-file/import-file.component';
import { FormsModule } from '@angular/forms';


const I18N_VALUES = {
  fr: {
    weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
    months: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
  }
  // other languages you would support
};

// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
@Injectable()
export class I18n {
  language = 'fr';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  // tslint:disable-next-line:variable-name
  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }

  getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    return this.getWeekdayShortName(weekday);
  }

}

@Component({
    selector: 'app-ade-new',
    templateUrl: './ade-new.component.html',
    styleUrls: ['./ade-new.component.scss'],
    providers: [I18n, { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
    standalone: true,
    imports: [FormsModule, NgClass, ImportFileComponent, NgbInputDatepicker]
})
export class AdeNewComponent implements OnInit {

  @Input() client = null;
  @Output() formation: EventEmitter<any> = new EventEmitter<any>();

  datedebut = null;
  origFormation: IAdeFormation;
  newList: any = null;
  newFormation: IAdeFormation;
  stagiaires = [];
  contactlist: IContactList;
  isSpinnerVisible = false;

  constructor(
    private adeService: AllianceDistributionService,
    private contactListService: ContactListService,
    private toastr: ToastrService,


  ) { }

  ngOnInit() {
    console.log('ade edit');
    this.datedebut = new Date(moment().format('YYYY-MM-DD'));
    this.newFormation = { id: 0, name: '', listid: 0, stage: '', date_debut: '', dates: '', ville: '', nbparticipants: 0, nbreponses: 0, nbyes: 0, nbno: 0 };
  }

  cancel() {
    this.formation.emit(this.origFormation);
  }

  create() {
    this.isSpinnerVisible = true;
    this.newFormation.date_debut = this.datedebut;
    // remove extra "
    this.newFormation.name = this.newFormation.name.replace(/"/g, '');
    console.log('update stage', this.newFormation);
    this.adeService.CreateAdeStage(this.client.id, this.newFormation).subscribe( (data: any) => {
      if (data.success) {
        this.toastr.success('Le stage a été créé', '');

        console.log('new stage id', data.id);
        this.newFormation.id = data.id;
        // add staginewFormationaire into this formation
        const promises = [];
        for (const s of this.stagiaires) {
            s.stageid = this.newFormation.id;
            promises.push(this.adeService.SaveAdeStagiaire(this.newFormation.id, s).toPromise().then( res => {
              console.log('stagiaire', res);
            }));
        }
        promises.push(this.contactListService.GetList(this.client.id, this.newFormation.listid).toPromise().then((clist: any) => {
          this.contactlist = clist;
        }));

        Promise.all(promises).then( _ => {
            const list = {
              id: this.newFormation.listid,
              longurl: 'https://i-sms.pro/ade/index?idstage='+ this.newFormation.id +
              '&idlist=' + this.newFormation.listid +
              '&telephone=%' + this.contactlist.mobile + '%',
            };
            this.contactListService.SetUrl(this.client.id, list).subscribe( (clist) => console.log('longurl set', clist));
            this.formation.emit(this.newFormation);
        });

      } else {
        this.toastr.error(data.message, 'Erreur', { timeOut: 0 });
      }
      this.isSpinnerVisible = false;
    });
  }

  closeImport(listid) {
    console.log('import closed', listid);

    this.contactListService.ContactGetAll(this.client.id, listid).subscribe( (data: any) => {
      /*
        0 N° PARTICIPANT
        2 DEPOT OU SE DEROULE LE STAGE
        9 STAGE
        10 NOM DU STAGE
        11 DATES FORMATION
        12 DATES COMPLETES
        19 PRENOM STAGIAIRE
        20 NOM  STAGIAIRE
        24 N° TELEPHONE
        25 MAIL
        */

        // extract stage info from first stagiaire
        if (data.length > 0) {
          const infos = JSON.parse(data[0].json_data);
          this.newFormation.ville = infos[2].replace(/"/g, '');
          this.newFormation.stage = infos[9].replace(/"/g, '');
          this.newFormation.name = infos[10].replace(/"/g, '');
          this.datedebut = moment(infos[11] + '12:00', 'DD/MM/YYYY HH:mm', 'fr').toDate();
          this.newFormation.dates = infos[12].replace(/"/g, '');
          this.newFormation.listid = listid;
          this.newFormation.nbparticipants = 0;
          const stagiaires = [];
          for (let i = 0; i < data.length; i++) {
              // tslint:disable-next-line: no-shadowed-variable
              const infos = JSON.parse(data[i].json_data);
              const stagiaire = {
                index: i + 1,
                contactid: data[i].id,
                nom: infos[20],
                prenom: infos[19],
                mobile: infos[24],
                email: infos[25],
                stageid: 0 };
              stagiaires.push(stagiaire);
          }
          this.stagiaires = R.clone(stagiaires);
        }
    });
  }

}
