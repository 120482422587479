<div class="client-view p-md-4">

  @if (!client) {
  <div>
    <app-client-select [selectedUser]="client"></app-client-select>
  </div>
  } @else {
  <div class="card">
    <div class="card-body">

      <h4>Historique des campagnes</h4>

      <ul class="nav nav-pills mb-n3" id="myTab" role="tablist">
        <li class="nav-item">
          <div class="nav-link" [ngClass]="{'active': activeTab===0}" id="tab0" data-toggle="tab" (click)="selectTab(0)"
            role="tab" aria-controls="toutes" aria-selected="true">Toutes les campagnes</div>
        </li>
        <li class="nav-item">
          <div class="nav-link" [ngClass]="{'active': activeTab===1}" id="tab1" data-toggle="tab" (click)="selectTab(1)"
            role="tab" aria-controls="statcamp" aria-selected="false">Stat campagne</div>
        </li>
        <li class="nav-item">
          <div class="nav-link" [ngClass]="{'active': activeTab===2}" id="tab2" data-toggle="tab" (click)="selectTab(2)"
            role="tab" aria-controls="archivées" aria-selected="false">Stat client</div>
        </li>
      </ul>
      <hr>

      <div class="tab-content" id="myTabContent">

        @if (activeTab === 0) {
        <div class="tab-pane fade show active" id="coord" role="tabpanel" aria-labelledby="coord-tab">

          <app-mlp-list [client]='client' [commerciaux]='commerciaux' [chefsRegion]='chefsRegion'
            [customers]='customers' (selectedCampaigns)="selectCampaigns($event)">
          </app-mlp-list>

        </div>
        }
      </div>

      <div class="tab-content" id="myTabContent">

        @if (activeTab === 1) {
        <div class="tab-pane fade show active" id="coord" role="tabpanel" aria-labelledby="coord-tab">

          <app-mlp-campagne [client]='client' [filterParams]='filterParams' [selectedCampaigns]="selectedCampaigns"
            [commerciaux]='commerciaux' [chefsRegion]='chefsRegion' [customers]='customers'
            (selectNim)="selectNim($event)" (openPhoto)="showPhoto($event)">
          </app-mlp-campagne>

        </div>
        }
      </div>

      <div class="tab-content" id="myTabContent">

        @if (activeTab === 2) {
        <div class="tab-pane fade show active" id="coord" role="tabpanel" aria-labelledby="coord-tab">

          <app-mlp-client [client]='client' [diffuseur]="diffuseur" [commerciaux]='commerciaux'
            [chefsRegion]='chefsRegion' [customers]='customers' (openPhoto)="showPhoto($event)">
          </app-mlp-client>

        </div>
        }
      </div>

    </div>
  </div>
  }
</div>


<app-modal [(isActive)]="showPhotos" [isLarge]="true" [overlayDismiss]="true" (overlayClick)="showPhotos=false">
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <div class="d-flex justify-content-center">
          <div class="w100" (click)="doRotate(1)">
            <i class="fa fa-2x fa-undo"></i>
          </div>
          <div class="w100" (click)="doRotate(-1)">
            <i class="fa fa-2x fa-repeat"></i>
          </div>
          <div class="w100" (click)="openFull()">
            <i class="fa fa-2x fa-external-link" style="margin-top:3px"></i>
          </div>
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showPhotos=false">
          <i class="fa fa-2x fa-times" style="margin-top:-12px"></i>
        </button>
      </div>

      @if (photos && photos[photoIndex]) {
      <div class="modal-body">
        <div class="">
          <img [src]="imgUrl + photos[photoIndex]" class="photo_full">
        </div>
        <div class="d-flex m-auto photo_selector">
          @for (p of photos; track p; let i = $index) {
          <div>
            <img [src]="imgUrl + p" class="photo_small" (click)="photoIndex=i">
          </div>
          }
        </div>
      </div>
      }

    </div>
  </div>
</app-modal>
