<h3>Edition d'une formation:</h3>

<hr>
@if (!selectedFormation) {
<div class="alert alert-danger">Séléctionnez en premier un stage dans la liste 'Tous les
  stages'</div>
} @else {
<form name="form" role="form" autocomplete="off" autocapitalize="off" autocorrect="off" #f="ngForm">

  <div class="form-group">
    <div class="col-12">
      <div class="form-group">
        <label class="h5 control-label" for="stagename">Nom:</label>
        <input type="text" name="stagename" id="stagename" class="form-control" [(ngModel)]="selectedFormation.name"
          autocomplete="off" autocapitalize="off" autocorrect="off" placeholder="Nom de la formation ou du stage"
          required #nameRef="ngModel" />
        @if (nameRef.errors?.required) {
        <div class="text text-danger">Champ obligatoire</div>
        }
      </div>
    </div>
    <div class="form-check col-12">
      <div class="row">
        <div class="col-12 col-sm-4">
          <label class="h5" for="date">Date de début</label>
          <div class="input-group">
            <input class="form-control" placeholder="yyyy-mm-dd" name="datedebut" [(ngModel)]="datedebut" ngbDatepicker
              #d1="ngbDatepicker" (click)="d1.toggle()">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary btn-grey" (click)="d1.toggle()" type="button"><i
                  class="fa fa-calendar"></i></button>
            </div>
          </div>
        </div>
        <div class="col-6 col-sm-4">
          <label class="h5" for="stage">Stage</label>
          <div class="input-group">
            <input type="text" class="form-control" id="stage" name="stage" [(ngModel)]="selectedFormation.stage"
              autocomplete="off" autocapitalize="off" placeholder="Stage" required>
          </div>
        </div>
        <div class="col-6 col-sm-4">
          <label class="h5" for="ville">Ville</label>
          <div class="input-group">
            <input type="text" class="form-control" id="ville" name="ville" [(ngModel)]="selectedFormation.ville"
              autocomplete="off" autocapitalize="off" placeholder="Ville" required>
          </div>
        </div>
      </div>
    </div>
    <div class="form-check col-12">
      <div class="row">
        <div class="col-8 col-sm-4">
          <label class="h5" for="dates">Dates complètes</label>
          <div class="input-group">
            <input type="text" class="form-control" id="dates" name="dates" [(ngModel)]="selectedFormation.dates"
              autocomplete="off" autocapitalize="off" placeholder="Dates complètes" required>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-actions d-flex">

    <button class="btn btn-outline-secondary" (click)="cancel()">Annuler</button>
    <button [disabled]="f.invalid" class="btn btn-primary ml-auto" (click)="modify()">Modifier</button>

  </div>
</form>
}
<br>
<br>
