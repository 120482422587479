import { ViewChildren, QueryList, Component, OnInit, AfterViewInit, Input, OnDestroy, Injectable } from '@angular/core';
import { Observable, ReplaySubject, of } from 'rxjs';
import { SortEvent, NgbdSortableHeader } from 'src/app/directives/sortable.directive';
import { environment } from '../../../environments/environment';

import { PagingService } from '../../providers/paging.service';
import { UserService } from 'src/app/providers/user.service';
import { Router } from '@angular/router';

import * as R from 'ramda';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ICredit } from 'src/app/interfaces/credit';
import { IUser, User } from 'src/app/interfaces/user';
import { AuthenticationService } from 'src/app/providers/authentication.service';

import { NgbDateAdapter, NgbDateStruct, NgbDateNativeAdapter, NgbDatepickerI18n, NgbInputDatepicker, NgbHighlight, NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { Chart } from 'chart.js';
import { ExportDataService } from 'src/app/providers/export-data.service';

import moment from 'moment';
import { TranslationWidth, NgClass, AsyncPipe } from '@angular/common';
import { ModalComponent } from '../../modals/modal.component';
import { NgbdSortableHeader as NgbdSortableHeader_1 } from '../../directives/sortable.directive';
import { FormsModule } from '@angular/forms';
import { SpinnerComponent } from '../spinner/spinner.component';
import { ClientSelectComponent } from '../client-select/client-select.component';


const I18N_VALUES = {
  fr: {
    weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
    months: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
  }
  // other languages you would support
};

// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
@Injectable()
export class I18n {
  language = 'fr';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  // tslint:disable-next-line:variable-name
  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }

  getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    return this.getWeekdayShortName(weekday);
  }

}


@Component({
    selector: 'app-credit-history',
    templateUrl: './credit-history.component.html',
    styleUrls: ['./credit-history.component.scss'],
    providers: [I18n, { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
    standalone: true,
    imports: [ClientSelectComponent, SpinnerComponent, FormsModule, NgbInputDatepicker, NgClass, NgbdSortableHeader_1, NgbHighlight, NgbPagination, ModalComponent, AsyncPipe]
})
export class CreditHistoryComponent implements OnInit, OnDestroy {

  credits$: Observable<ICredit[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;

  selectedCredit: ICredit = null;
  client: IUser = null;
  userSubscribe = null;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  page = 1;
  pageSize = 10;
  collectionSize = 1;
  searchFields = ['creditid', 'campaignid', 'label', 'dateoperation', 'premium', 'test'];
  isAdmin = this.authService.isAdmin;

  showDeleteConfirm = false;
  initialized = false;
  dateChanged = false;

  period = {
    startdate: new Date(moment().format('YYYY-MM-01')),
    stopdate: new Date(moment().format('YYYY-MM-') + moment().endOf('month').format('DD'))
  };
  origPeriod = R.clone(this.period);

  selectedPeriod = {
    startdate: moment(this.period.startdate).format('YYYY-MM-DD'),
    stopdate: moment(this.period.stopdate).format('YYYY-MM-DD'),
  };

  consoPeriod: any;
  consoSubscribe = null;

  chart = null;

  showAddCredit = false;
  creditType = 1;   // premium=1, lowcost=0
  credits = 0;
  isSpinnerVisible = false;


  // // provide your own if necessary
  // providers: [{provide: NgbDatepickerI18n, useClass: YourOwnDatepickerI18n}]

  constructor(
    public pagingService: PagingService<ICredit>,
    private authService: AuthenticationService,
    private userService: UserService,

    private toastr: ToastrService,
    private exportDataService: ExportDataService,
    private router: Router,
  ) {
    // this.client = new User();
    // this.client.id = 0;
    this.credits$ = pagingService.rowsObs;
    this.total$ = pagingService.totalObs;
    this.loading$ = pagingService.loadingObs;
    this.pagingService.searchTerm = '';
    // set fields for filtering
    this.pagingService.fields = this.searchFields;

  }

  ngOnInit(): void {

    // console.log('credits onInit', this.client);
    this.pagingService.loadRows(of([]));
    this.userSubscribe = this.userService.selectedUser$.subscribe(user => {
      if (user) {

        if (user.id !== 0) {
          this.client = R.clone(user);
          if (this.client) {
            this.pagingService.loadRows(this.userService.GetCreditsHistory(this.client.id, this.selectedPeriod));
            this.consoSubscribe = this.userService.GetCreditsHistoryAll().subscribe( (conso: any) => {
              if (conso) this.consoPeriod = conso;
            });
            this.loading$.subscribe(loading => {
              if (loading) {
                this.isSpinnerVisible = true;
              } else {
                this.isSpinnerVisible = false;
                this.initialized = true;
              }
            });
            this.pagingService.page = 1;
          }
          this.loadChart();
        }
      }
    });

  }

  ngOnDestroy() {
    if (this.userSubscribe) {
      this.userSubscribe.unsubscribe();
    }
    if (this.consoSubscribe) {
      this.consoSubscribe.unsubscribe();
    }
    if (this.chart) {
      this.chart.destroy();
    }
  }

  pageChanged(event) {
    console.log('onPageChange', event);
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.pagingService.sortColumn = column;
    this.pagingService.sortDirection = direction;
  }

  AddPremium() {
    this.creditType = 1;
    this.credits = 0;
    this.showAddCredit = true;
  }

  AddCredits() {
    this.isSpinnerVisible = true;
    this.showAddCredit = false;
    this.userService.AddCredits(this.client.id, this.creditType, this.credits)
    .subscribe((response: any) => {
        if (response.success) {
            this.toastr.success('Les crédits ont été ajoutés', '');
            this.pagingService.loadRows(this.userService.GetCreditsHistory(this.client.id, this.selectedPeriod));
            // reload also client infos
            this.userService.GetById(this.client.id).subscribe( (res: any) => {
                if (typeof res.success !== 'undefined') {
                  this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
                } else {
                  this.userService.selectedUser$.next(res as IUser);
                }
            });
            this.credits = 0;
            this.creditType = 1;
        } else {
          this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
        }
        this.isSpinnerVisible = false;
      });

  }

  selectCredit(credit) {
    this.selectedCredit = R.clone(credit);
  }

  checkDate() {
    this.dateChanged = this.origPeriod.startdate !== this.period.startdate ||
          this.origPeriod.stopdate !== this.period.stopdate;
  }

  selectPeriod() {

    if (!this.client || this.client.id === 0) { return; }

    this.selectedPeriod = {
      startdate: moment(this.period.startdate).format('YYYY-MM-DD'),
      stopdate: moment(this.period.stopdate).format('YYYY-MM-DD'),
    };
    this.origPeriod = R.clone(this.period);
    this.dateChanged = false;

    this.pagingService.loadRows(this.userService.GetCreditsHistory(this.client.id, this.selectedPeriod));
  }

  loadChart() {

    this.userService.GetConso(this.client.id, 12).subscribe((data: any) => {
      if (data.success) {
        // histo = data.conso;
        // vm.histo.data = data.conso.data;
        //         vm.histo.series = data.conso.series;
        //         vm.histo.labels = data.conso.labels;
        //         vm.histo.loaded = true;


        const histo =  {
          labels: data.conso.labels,
          datasets: [{
            label: 'Premium',
            backgroundColor: '#3fbfad55',
            borderColor: '#3fbfad',
            hoverBackgroundColor: '#3fbfad88',
            borderWidth: 1,
            data: data.conso.data[0],
          }, {
            label: 'Lowcost',
            backgroundColor: '#eaa21155',
            borderColor: '#eaa211',
            hoverBackgroundColor: '#eaa21188',
            borderWidth: 1,
            data: data.conso.data[1],
          }]
        };

        console.log(data);
        console.log(histo);

        if (this.chart) {
          this.chart.destroy();
        }

        this.chart = new Chart('canvas', {
          type: 'bar',
          data: histo,
          options: {
            responsive: true,
            legend: {
              position: 'top',
            },
            title: {
              display: false,
            }
          }
        });

        // this.chart = new Chart('canvas', {
        //   type: 'bar',
        //   data: {
        //     labels: data.conso.labels,
        //     barPercentage: 0.5,
        //     datasets: [
        //       {
        //         type: 'bar',
        //         label: '#Premium',
        //         backgroundColor: '#3fbfad55',
        //         borderColor: '#3fbfad',
        //         hoverBackgroundColor: '#3fbfad88',
        //         borderWidth: 1,
        //         yAxisID: 'A',
        //         data: data.conso.series[0]
        //       },
        //       {
        //         type: 'bar',
        //         label: '#Lowcost',
        //         backgroundColor: '#bf3fad55',
        //         borderColor: '#bf3fad',
        //         hoverBackgroundColor: '#bf3fad88',
        //         borderWidth: 1,
        //         yAxisID: 'A',
        //         data: data.conso.series[1]
        //       }
        //     ]
        //   },
        //   options: {
        //     legend: {
        //       display: true
        //     },
        //     scales: {
        //       xAxes: [{
        //         barPercentage: 1.0,
        //         categoryPercentage: 0.5,
        //         display: true,
        //       }],
        //       yAxes: [{
        //         id: 'A',
        //         type: 'linear',
        //         position: 'left',
        //         display: true,
        //         ticks: {
        //           beginAtZero: true,
        //           stepSize: 10
        //         }
        //       }]
        //     }
        //   }
        // });
      } else {
        this.toastr.error('Erreur lors du chargement des consommations', 'Erreur', { timeOut: 0 });
      }

    });

  }

  Export(kind) {
    console.log('export', kind);
    const records = [];
    const fields = ['creditid', 'campaignid', 'label', 'dateoperation', 'test', 'premium'];
    for (const u of this.pagingService.filteredRows) {
      const user = R.pick(fields, u);
      records.push(user);
    }
    const header = {};
    fields.forEach(f => header[f] = f.replace(/[^a-z0-9]/gmi, '').toUpperCase());
    records.unshift(header);
    this.exportDataService.exportAs(kind, records, 'credit_' + this.client.id);
  }


}

