<br>
@if (!formation) {
<div>
  <div class="alert alert-danger w-100">Séléctionnez en premier un stage dans la liste 'Tous les stages'</div>
</div>
} @else {
<div class="alert alert-well form-info">
  <h4>{{formation?.name}}</h4>
  <div class="row">
    <div class="col-6">Type: {{formation?.stage}}</div>
    <div class="col-6">Ville: {{formation?.ville}}</div>
  </div>
  <div class="row">
    <div class="col-6">Date début: {{formation?.date_debut |date : 'dd/MM/yyyy'}}</div>
    <div class="col-6">Dates: {{formation?.dates}}</div>
  </div>
  <div class="row">
    <div class="col-6">Nbre de participants prévus: {{formation?.nbparticipants}}</div>
    <div class="col-6">Nbre de réponses reçues: {{formation?.nbreponses}}</div>
  </div>
  <div class="row">
    <div class="col-6">Nbre de OUI: {{formation?.nbyes}}</div>
  </div>
  <div class="row">
    <div class="col-6">Nbre de NON: {{formation?.nbno}}</div>

    <div class="ml-auto">
      <button class=" btn btn-secondary" (click)="edit()"><i class="fa fa-edit"></i>&nbsp;Editer</button>
    </div>
  </div>
</div>
}

<div class="row mt-2 mb-2">
  <div class="col-4">
    <button class="btn btn-success" (click)="createCampaign('0')"><i
        class="fa fa-sign-out"></i>&nbsp;{{formation?.nbreponses>0 ? 'Relancer' : 'Lancer'}} une campagne
      SMS</button>
  </div>
  <div class="col-4">
    <button class="btn btn-info" (click)="addStagiaire()"><i class="fa fa-plus"></i>&nbsp;Ajouter un stagiaire</button>
  </div>
</div>

<hr>


<div class="clearfix">
  <form class="inline">
    <button type="button" (click)="Export('TEXT')" class="btn btn-sm btn-outline-secondary ml-2 h-100">Copy</button>
    <button type="button" (click)="Export('CSV')" class="btn btn-sm btn-outline-secondary ml-2 h-100">CSV</button>
    <button type="button" (click)="Export('EXCEL')" class="btn btn-sm btn-outline-secondary ml-2 h-100">Excel</button>
    <button type="button" (click)="Export('PRINT')" class="btn btn-sm btn-outline-secondary ml-2 h-100">Print</button>

    <div class="form-group form-inline ml-auto">
      Recherche: <input class="form-control ml-2" type="text" name="searchTerm"
        [(ngModel)]="pagingService.searchTerm" />
    </div>
  </form>


  <table id="selectedColumn" class="table table-bordered table-striped table-hover table-sm" cellspacing="0"
    width="100%">
    <thead>
      <tr>
        <th class="th" sortable="id" (sort)="onSort($event)">ID</th>
        <th class="th" sortable="prenom" (sort)="onSort($event)">Prénom</th>
        <th class="th" sortable="nom" (sort)="onSort($event)">Nom</th>
        <th class="th collapsible" sortable="mobile" (sort)="onSort($event)">Téléphone</th>
        <th class="th" sortable="reponse" (sort)="onSort($event)">Réponse</th>
        <th class="th" sortable="date_reponse" (sort)="onSort($event)">Date réponse</th>
        <th class="th collapsible" sortable="commentaires" (sort)="onSort($event)">Commentaire</th>
        <th class="th collapsible">#SMS envoyé/OK</th>
        <th class="th collapsible" sortable="date_last_sms" (sort)="onSort($event)">Date dernier SMS</th>
      </tr>
    </thead>
    @if (initialized) {
    <tbody>
      @for (s of stagiaires$ | async; track s) {
      <tr>
        <td>
          <ngb-highlight [result]="s.id" [term]="pagingService.searchTerm"></ngb-highlight>
        </td>
        <td>
          <ngb-highlight [result]="s.prenom" [term]="pagingService.searchTerm"></ngb-highlight>
        </td>
        <td>
          <ngb-highlight [result]="s.nom" [term]="pagingService.searchTerm"></ngb-highlight>
        </td>
        <td class="collapsible">
          <ngb-highlight [result]="s.mobile" [term]="pagingService.searchTerm">
          </ngb-highlight>
        </td>
        <td>
          <ngb-highlight [result]="s.reponse" [term]="pagingService.searchTerm"></ngb-highlight>
        </td>
        <td>
          <ngb-highlight [result]="s.date_reponse=='0000-00-00 00:00:00' ? '' : s.date_reponse"
            [term]="pagingService.searchTerm"></ngb-highlight>
        </td>
        <td class="collapsible">
          <ngb-highlight [result]="s.commentaires" [term]="pagingService.searchTerm">
          </ngb-highlight>
        </td>
        <td class="collapsible">
          {{s.smsnb}} / {{s.smsok}}
        </td>
        <td class="collapsible">
          <ngb-highlight [result]="s.date_last_sms=='0000-00-00 00:00:00' ? '' : s.date_last_sms"
            [term]="pagingService.searchTerm">
          </ngb-highlight>
        </td>
      </tr>
      }
    </tbody>
    }
  </table>

  <div class="inline">
    <div class="">
      Total: {{(total$ | async)}}
    </div>
    <div class="ml-auto">
      <ngb-pagination class="d-flex justify-content-end" [collectionSize]="total$ | async" [(page)]="pagingService.page"
        [boundaryLinks]="true" [rotate]="true" [directionLinks]="true" [pageSize]="pagingService.pageSize" [maxSize]="3"
        name="page">
      </ngb-pagination>
    </div>
  </div>
</div>

<app-modal [(isActive)]="showAddStagiaire" [overlayDismiss]="true" (overlayClick)="showAddStagiaire=false"
  [isXLarge]="true" class="modal-dialog modal-dialog-scrollable">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">AJout d'un stagiaire</h5>
      <div class="close" data-dismiss="modal" aria-label="Close" (click)="showAddStagiaire=false">
        <span aria-hidden="true">&times;</span>
      </div>
    </div>

    <div class="modal-body">
      <div class="mb-3 w-80">Veuillez saisir les champs pour votre contact (Format en +336xxx ou +337xxx pour le numéro)
      </div>
      <hr>
      <form role="form" name="form" class="scrollable">
        @for (f of testVars?.fields; track f; let i = $index) {
        <div>
          @if (testVars.mandatory[i]) {
          <div class="form-group">
            <label for="f{{i}}">{{f}}</label>
            <input type="text" name="f[i]" id="f[i]" class="form-control" [(ngModel)]="testVars?.values[i]"
              autocomplete="off" autocapitalize="off" autocorrect="off">
          </div>
          }
        </div>
        }
      </form>
    </div>

    <div class="modal-footer">
      <div class="btn btn-primary" (click)='addStagiaireConfirm()'>Envoyer</div>
      <div class="btn btn-secondary" data-dismiss="modal" (click)="showAddStagiaire=false">Annuler</div>
    </div>
  </div>
</app-modal>
