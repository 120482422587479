
import { Component, OnInit, Input, EventEmitter, Output, Injectable } from '@angular/core';
import { IAnnonce } from 'src/app/interfaces/annonce';

import * as R from 'ramda';
import moment from 'moment';
import { AnnonceService } from 'src/app/providers/annonce.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContactListService } from 'src/app/providers/contact-list.service';
import { IContactList } from 'src/app/interfaces/contact-list';
import { AgenceService } from 'src/app/providers/agence.service';
import { Agence } from 'src/app/interfaces/agence';
import { HttpClient } from '@angular/common/http';
import { ModalComponent } from '../../modals/modal.component';
import { FormsModule } from '@angular/forms';


@Component({
    selector: 'app-annonce-edit',
    templateUrl: './annonce-edit.component.html',
    styleUrls: ['./annonce-edit.component.scss'],
    standalone: true,
    imports: [FormsModule, ModalComponent]
})
export class AnnonceEditComponent implements OnInit {

  @Input() client = null;
  @Input() selectedAnnonce = null;
  @Output() annonce: EventEmitter<any> = new EventEmitter<any>();

  origAnnonce: IAnnonce;
  showDeleteConfirm = false;
  isSpinnerVisible = false;

  constructor(
    private annonceService: AnnonceService,
    private toastr: ToastrService,

  ) { }

  ngOnInit() {
    this.origAnnonce = R.clone(this.selectedAnnonce);
  }

  loadPreviewAnnonce() {
    window.open(
      this.selectedAnnonce.url,
      '',
      'toolbar=no, menubar=no,scrollbars=yes,resizable=yes,location=no,directories=no,status=no, width=1000, height=700');
  }

  cancel() {
    console.log('cancel edit');
    this.annonce.emit(this.origAnnonce);
  }

  modify() {
    this.isSpinnerVisible = true;
    this.annonceService.Update(this.client.id, this.selectedAnnonce).subscribe( (data: any) => {
      if (data.success) {
        console.log('exit edit');
        this.toastr.success('L\'annonce a été modifiée', '');
        this.annonce.emit(this.selectedAnnonce);
      } else {
        this.toastr.error(data.message, 'Erreur', { timeOut: 0 });
      }
      this.isSpinnerVisible = false;
    });
  }

  delete() {
    this.showDeleteConfirm = true;
    console.log('show delete confirm');
  }

  deleteConfirm() {
    this.showDeleteConfirm = false;
    console.log('call delete api');
    this.isSpinnerVisible = true;

    this.annonceService.Delete(this.client.id, this.selectedAnnonce.id).subscribe((response: any) => {
      if (response.success) {
        this.toastr.success('L\'annonce a été supprimée', '');
        this.annonce.emit(null);
      } else {
        this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
      }
      this.isSpinnerVisible = false;
    });
  }

}
