import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../providers/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard  {

  isAdmin = false;
  constructor(
    private authService: AuthenticationService,
    private router: Router,
  ) {
    this.isAdmin = this.authService.isAdmin;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.isAdmin) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}
