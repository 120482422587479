@if (isAdmin) {
<div class="client-view p-md-4">

  <app-spinner [visible]="isSpinnerVisible"></app-spinner>

  <div class="card">
    <div class="card-body">

      <h3>Consommations Crédits</h3>

      <ul class="nav nav-pills mb-n3" id="myTab" role="tablist">
        <li class="nav-item">
          <div class="nav-link" [ngClass]="{'active': activeTab===0}" id="tab0" data-toggle="tab" (click)="selectTab(0)"
            role="tab" aria-controls="comptes" aria-selected="true">Tous les comptes - NEW</div>
        </li>
        <li class="nav-item">
          <div class="nav-link" [ngClass]="{'active': activeTab===4}" id="tab4" data-toggle="tab" (click)="selectTab(4)"
            role="tab" aria-controls="comptes" aria-selected="true">Tous les comptes</div>
        </li>
        <li class="nav-item">
          <div class="nav-link" [ngClass]="{'active': activeTab===1}" id="tab1" data-toggle="tab" (click)="selectTab(1)"
            role="tab" aria-controls="postpaid" aria-selected="false">Comptes POSTPAID</div>
        </li>
        <li class="nav-item">
          <div class="nav-link" [ngClass]="{'active': activeTab===2}" id="tab2" data-toggle="tab" (click)="selectTab(2)"
            role="tab" aria-controls="forfait" aria-selected="false">Comptes FORFAIT</div>
        </li>
        <li class="nav-item">
          <div class="nav-link" [ngClass]="{'active': activeTab===3}" id="tab3" data-toggle="tab" (click)="selectTab(3)"
            role="tab" aria-controls="resume" aria-selected="false">Résumé</div>
        </li>
      </ul>
      <hr>

      @if (activeTab===0) {

      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active p-4" id="tab0" role="tabpanel" aria-labelledby="comptes">

          <form>
            <div class="row">
              <div class="form-group col-4">
                <label for="startdate">Date de début de période</label>
                <div class="input-group">
                  <input class="form-control" placeholder="yyyy-mm-dd" name="startdate" [(ngModel)]="period.startdate"
                    ngbDatepicker #d1="ngbDatepicker" (dateSelect)="checkDate()" (click)="d1.toggle()">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary btn-grey" (click)="d1.toggle()" type="button"><i
                        class="fa fa-calendar"></i></button>
                  </div>
                </div>
              </div>
              <div class="form-group col-4">
                <label for="stopdate">Date de fin</label>
                <div class="input-group">
                  <input class="form-control" placeholder="yyyy-mm-dd" name="stopdate" [(ngModel)]="period.stopdate"
                    ngbDatepicker #d2="ngbDatepicker" (dateSelect)="checkDate()" (click)="d2.toggle()">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary btn-grey" (click)="d2.toggle()" type="button"><i
                        class="fa fa-calendar"></i></button>
                  </div>
                </div>
              </div>
              <div class="form-group col-4">
                <button class="btn" style="margin-top: 32px;"
                  [ngClass]="{'btn-success blink':dateChanged, 'btn-info': !dateChanged}"
                  (click)="selectPeriod()">Rechercher</button>
              </div>
            </div>

          </form>


          <div class="canvas">
            <canvas id="canvas">{{ chart }}</canvas>
          </div>

          <hr>

          <div class="ml-1">
            <div class="d-flex">
              <div class="premium p-1">Premium:
                {{consoPeriod?.totalPremium}}&nbsp;&nbsp;({{consoPeriod?.moyPremium}}/jour)</div>
              <div class="lowcost p-1 ml-auto">LowCost: {{consoPeriod?.totalLowcost}}</div>
            </div>
          </div>
          <br>

          <div class="p-20">
            <div class="clearfix">
              <form class="inline">
                <button type="button" (click)="Export('TEXT')"
                  class="btn btn-sm btn-outline-secondary ml-2 h-100">Copy</button>
                <button type="button" (click)="Export('CSV')"
                  class="btn btn-sm btn-outline-secondary ml-2 h-100">CSV</button>
                <button type="button" (click)="Export('EXCEL')"
                  class="btn btn-sm btn-outline-secondary ml-2 h-100">Excel</button>
                <button type="button" (click)="Export('PRINT')"
                  class="btn btn-sm btn-outline-secondary ml-2 h-100">Print</button>

                <div class="form-group form-inline ml-auto">
                  Recherche: <input class="form-control ml-2" type="text" name="searchTerm"
                    [(ngModel)]="pagingService.searchTerm" />
                </div>
              </form>

              <table id="selectedColumn" class="table table-bordered table-striped table-hover table-sm" cellspacing="0"
                width="100%">
                <thead>
                  <tr>
                    <th class="th" sortable="id" (sort)="onSort($event)">ID</th>
                    <th class="th" sortable="customerid" (sort)="onSort($event)">ID compagnie</th>
                    <th class="th collapsible" sortable="company" (sort)="onSort($event)">Compagnie</th>
                    <th class="th" sortable="period" (sort)="onSort($event)">Date</th>
                    <th class="th" sortable="premium" (sort)="onSort($event)">Premium / jour</th>
                    <th></th>
                  </tr>
                </thead>
                @if (initialized) {
                <tbody>
                  <tr *ngFor="let conso of credits$ | async" >
                    <td>{{conso.id}}</td>
                    <td>
                      <ngb-highlight [result]="conso.customerid" [term]="pagingService.searchTerm">
                      </ngb-highlight>
                    </td>
                    <td>
                      <ngb-highlight [result]="conso.company" [term]="pagingService.searchTerm">
                      </ngb-highlight>
                    </td>
                    <td>
                      <ngb-highlight [result]="conso.period" [term]="pagingService.searchTerm">
                      </ngb-highlight>
                    </td>
                    <td class="text-success">{{conso.premium}}</td>
                    <td>
                      <div class="inline">
                        <button type="button" title="Voir les campagnes" (click)="selectAndShowCampaigns(conso)"
                          class="btn btn-sm btn-success ml-1 w30"><i class="fa fa-play"></i></button>
                        <button type="button" title="Voir les crédits" (click)="selectAndShowCredits(conso)"
                          class="btn btn-sm btn-info ml-1 w30"><i class="fa fa-info"></i></button>
                      </div>
                    </td>
                  </tr>
                </tbody>
                }
              </table>

              <div class="inline">
                <div class="">
                  Total: {{(total$ | async)}}
                </div>
                <div class="ml-auto">
                  <ngb-pagination class="d-flex justify-content-end" [collectionSize]="total$ | async"
                    [(page)]="pagingService.page" [boundaryLinks]="true" [rotate]="true" [directionLinks]="true"
                    [pageSize]="pagingService.pageSize" [maxSize]="3" name="page">
                  </ngb-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      }

      @if (activeTab===4) {

        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active p-4" id="tab0" role="tabpanel" aria-labelledby="comptes">

            <form>
              <div class="row">
                <div class="form-group col-4">
                  <label for="startdate">Date de début de période</label>
                  <div class="input-group">
                    <input class="form-control" placeholder="yyyy-mm-dd" name="startdate" [(ngModel)]="period.startdate"
                      ngbDatepicker #d1="ngbDatepicker" (dateSelect)="checkDate()" (click)="d1.toggle()">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary btn-grey" (click)="d1.toggle()" type="button"><i
                          class="fa fa-calendar"></i></button>
                    </div>
                  </div>
                </div>
                <div class="form-group col-4">
                  <label for="stopdate">Date de fin</label>
                  <div class="input-group">
                    <input class="form-control" placeholder="yyyy-mm-dd" name="stopdate" [(ngModel)]="period.stopdate"
                      ngbDatepicker #d2="ngbDatepicker" (dateSelect)="checkDate()" (click)="d2.toggle()">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary btn-grey" (click)="d2.toggle()" type="button"><i
                          class="fa fa-calendar"></i></button>
                    </div>
                  </div>
                </div>
                <div class="form-group col-4">
                  <button class="btn" style="margin-top: 32px;"
                    [ngClass]="{'btn-success blink':dateChanged, 'btn-info': !dateChanged}"
                    (click)="selectPeriod4()">Rechercher</button>
                </div>
              </div>

            </form>


            <div class="canvas">
              <canvas id="canvas">{{ chart }}</canvas>
            </div>

            <hr>

            <div class="ml-1">
              <div class="d-flex">
                <div class="premium p-1">Premium:
                  {{consoPeriod?.totalPremium}}&nbsp;&nbsp;({{consoPeriod?.moyPremium}}/jour)</div>
                <div class="lowcost p-1 ml-auto">LowCost: {{consoPeriod?.totalLowcost}}</div>
              </div>
            </div>
            <br>

            <div class="p-20">
              <div class="clearfix">
                <form class="inline">
                  <button type="button" (click)="Export('TEXT')"
                    class="btn btn-sm btn-outline-secondary ml-2 h-100">Copy</button>
                  <button type="button" (click)="Export('CSV')"
                    class="btn btn-sm btn-outline-secondary ml-2 h-100">CSV</button>
                  <button type="button" (click)="Export('EXCEL')"
                    class="btn btn-sm btn-outline-secondary ml-2 h-100">Excel</button>
                  <button type="button" (click)="Export('PRINT')"
                    class="btn btn-sm btn-outline-secondary ml-2 h-100">Print</button>

                  <div class="form-group form-inline ml-auto">
                    Recherche: <input class="form-control ml-2" type="text" name="searchTerm"
                      [(ngModel)]="pagingService.searchTerm" />
                  </div>
                </form>

                <table id="selectedColumn" class="table table-bordered table-striped table-hover table-sm" cellspacing="0"
                  width="100%">
                  <thead>
                    <tr>
                      <th class="th" sortable="creditid" (sort)="onSort($event)">ID</th>
                      <th class="th" sortable="campaignid" (sort)="onSort($event)">Campagne</th>
                      <th class="th collapsible" sortable="company" (sort)="onSort($event)">Compagnie</th>
                      <th class="th collapsible" sortable="label" (sort)="onSort($event)">Achat / Campagne</th>
                      <th class="th" sortable="dateoperation" (sort)="onSort($event)">Date</th>
                      <th class="th" sortable="cpremium" (sort)="onSort($event)">Premium</th>
                      <th class="th" sortable="clowcost" (sort)="onSort($event)">Lowcost</th>
                      <th></th>
                    </tr>
                  </thead>
                  @if (initialized) {
                  <tbody>
                    <tr *ngFor="let credit of credits$ | async" (click)="selectCredit(credit)"
                      [ngClass]="{'selected': credit.creditid === selectedCredit?.creditid}">
                      <td>{{credit.creditid}}</td>
                      <td>
                        <ngb-highlight [result]="credit.campaignid" [term]="pagingService.searchTerm">
                        </ngb-highlight>
                      </td>
                      <td>
                        <ngb-highlight [result]="credit.company" [term]="pagingService.searchTerm">
                        </ngb-highlight>
                      </td>
                      <td class="collapsible">
                        <ngb-highlight [result]="credit.label" [term]="pagingService.searchTerm">
                        </ngb-highlight>
                      </td>
                      <td>
                        <ngb-highlight [result]="credit.dateoperation" [term]="pagingService.searchTerm">
                        </ngb-highlight>
                      </td>
                      <td class="text-success"
                        [ngClass]="{'text-right text-danger': credit.campaignid>0 || credit.test == 1}">
                        {{credit.lowcost>=0 ? credit.premium : ''}}
                      </td>
                      <td class="collapsible text-success" [ngClass]="{'text-right text-danger': credit.campaignid>0}">
                        {{ (credit.lowcost<0) ? credit.lowcost : "" }}
                      </td>
                      <td>
                        <div class="inline">
                          <button type="button" title="Voir les campagnes" (click)="selectAndShowCampaigns(credit)"
                            class="btn btn-sm btn-success ml-1 w30"><i class="fa fa-play"></i></button>
                          <button type="button" title="Voir les crédits" (click)="selectAndShowCredits(credit)"
                            class="btn btn-sm btn-info ml-1 w30"><i class="fa fa-info"></i></button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  }
                </table>

                <div class="inline">
                  <div class="">
                    Total: {{(total$ | async)}}
                  </div>
                  <div class="ml-auto">
                    <ngb-pagination class="d-flex justify-content-end" [collectionSize]="total$ | async"
                      [(page)]="pagingService.page" [boundaryLinks]="true" [rotate]="true" [directionLinks]="true"
                      [pageSize]="pagingService.pageSize" [maxSize]="3" name="page">
                    </ngb-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        }


      @if (activeTab === 1) {
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active p-4" id="tab0" role="tabpanel" aria-labelledby="postpaid">
          <app-conso-postpaid></app-conso-postpaid>
        </div>
      </div>
      }

      @if (activeTab === 2) {
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active p-4" id="tab0" role="tabpanel" aria-labelledby="forfait">
          <app-conso-forfait></app-conso-forfait>
        </div>
      </div>
      }

      @if (activeTab === 3) {
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active p-4" id="tab0" role="tabpanel" aria-labelledby="resume">
          <app-conso-recap></app-conso-recap>
        </div>
      </div>
      }

    </div>
  </div>
</div>
}
