
import { Component, OnInit, Output, EventEmitter, Input, QueryList, ViewChildren } from '@angular/core';
import { IUser } from 'src/app/interfaces/user';
import { PagingService } from 'src/app/providers/paging.service';
import { ExportDataService } from 'src/app/providers/export-data.service';
import { UserService } from 'src/app/providers/user.service';
import { MlpService } from 'src/app/providers/mlp.service';
import { AuthenticationService } from 'src/app/providers/authentication.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from 'src/app/directives/sortable.directive';

import * as R from 'ramda';
import { IMlpCampaign} from 'src/app/interfaces/mlp';
import { NgbHighlight, NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { NgbdSortableHeader as NgbdSortableHeader_1 } from '../../directives/sortable.directive';
import { FormsModule } from '@angular/forms';
import { AsyncPipe, DatePipe } from '@angular/common';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
    selector: 'app-mlp-campagne',
    templateUrl: './mlp-campagne.component.html',
    styleUrls: ['./mlp-campagne.component.scss'],
    standalone: true,
    imports: [FormsModule, NgbdSortableHeader_1, NgbHighlight, NgbPagination, AsyncPipe, DatePipe, SpinnerComponent]
})
export class MlpCampagneComponent implements OnInit {
  @Input() client: IUser = null;
  @Input() selectedCampaigns = [];
  @Input() commerciaux = [];
  @Input() chefsRegion = [];
  @Input() customers = [];
  @Input() filterParams = {startdate : '', stopdate : '',};
  @Output() selectNim: EventEmitter<any> = new EventEmitter<any>();
  @Output() openPhoto: EventEmitter<any> = new EventEmitter<any>();

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  page = 1;
  pageSize = 10;
  collectionSize = 1;
  searchFields = ['id', 'campaignid', 'chef_region', 'commercial', 'telephone', 'nim', 'client', 'smsok', 'totalphotos', 'commentaires'];

  campaigns = [];


  campaigns$: Observable<IMlpCampaign[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;
  initialized = false;
  isSpinnerVisible = false;

  progress$ = new BehaviorSubject<string>('');

  constructor(
    public pagingService: PagingService<IMlpCampaign>,
    private exportDataService: ExportDataService,
    private userService: UserService,
    private mlpService: MlpService,
    private authService: AuthenticationService,

  ) {
    this.campaigns$ = pagingService.rowsObs;
    this.total$ = pagingService.totalObs;
    this.loading$ = pagingService.loadingObs;
    this.pagingService.searchTerm = '';
    // set fields for filtering
    this.pagingService.fields = this.searchFields;

    // load campaigns from
  }

  ngOnInit() {
    this.pagingService.loadRows(of([]));
    this.pagingService.loadRows(this.mlpService.GetAllCampaign(this.selectedCampaigns));
    this.loading$.subscribe(loading => {
      if (loading) {
        this.isSpinnerVisible = true;
      } else {
        this.isSpinnerVisible = false;
        this.initialized = true;
      }
    });
    this.pagingService.page = 1;
  }

  pageChanged(event) {
    console.log('onPageChange', event);
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.pagingService.sortColumn = column;
    this.pagingService.sortDirection = direction;
  }

  displayExportProgress = (progress) => {
    this.progress$.next(progress);
    console.log(progress);
  }


  async Export(kind) {
    try {
      console.log('export', kind);
      this.isSpinnerVisible = true;

      const records = [];
      const images = [];
      const fields = ['id', 'campaignid', 'chef_region', 'commercial', 'telephone', 'nim', 'client', 'smsok', 'totalphotos', 'commentaires'];

      const addPhoto = (photo, rank, image) => {
        if (photo && photo.length > 0) {
          const url = 'https://i-sms.pro/mlp/' + photo;
          image.push({rank: rank, url });
        } else {
          image.push({rank: rank, url: null});
        }
      }

      for (const r of this.pagingService.filteredRows) {
        const record = R.pick(fields, r);
        if (kind === 'EXCEL') {
          const image = [];
          addPhoto(r.photo1 , 1, image);
          addPhoto(r.photo2 , 2, image);
          addPhoto(r.photo3 , 3, image);
          addPhoto(r.photo4 , 4, image);
          addPhoto(r.photo5 , 5, image);
          addPhoto(r.photo6 , 6, image);
          addPhoto(r.photo7 , 7, image);
          addPhoto(r.photo8 , 8, image);
          addPhoto(r.photo9 , 9, image);
          addPhoto(r.photo10, 10,image);
          images.push(image);
        }

        records.push(record);
      }
      const header = {};
      fields.forEach(f => header[f] = f.replace(/[^a-z0-9]/gmi, '').toUpperCase());
      records.unshift(header);
      const name = 'campagne' + (this.selectedCampaigns.length > 1 ? 's' : '_' + this.selectedCampaigns[0].id );

      await this.exportDataService.exportAs(kind, records, name , images, this.progress$);
    } catch (e) {
      console.log("error while export mllp campagne", e);
    }
    this.progress$.next('');
    this.isSpinnerVisible = false;
  }

  getChefRegion(cdrid) {
    return this.mlpService.getNameFromId(this.chefsRegion, cdrid);
  }

  getCommercial(commid) {
    return this.mlpService.getNameFromId(this.commerciaux, commid);
  }

  getCusto(nim) {
    const custo = this.customers.find( c => c.nim === nim);
    if (custo) {
      return custo.diffuseur;
    } else {
      return custo;
    }
  }

  showCusto(nim) {
    this.selectNim.emit(nim);
  }

  showPhoto(c) {
    this.openPhoto.emit(c);
  }

}


