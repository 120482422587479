import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as R from 'ramda';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  selectedCampaign = null;

  constructor(
    private httpClient: HttpClient,
  ) { }

  intersectFields(campaign, lists): any[] {

    console.log('intersectFileds');

    let totalContact = 0;
    campaign.hasURL = false;

    let index = 0;
    let fields = [];
    if (lists && lists.length > 0) {
      for (const list of lists) {
        if (list.selected === 1) {
          if (list.longurl !== '') {
            campaign.hasURL = true;
          }

          totalContact += parseInt(list.actif, 10);

          if (index++ === 0) {
            fields = JSON.parse(list.fields
              .replace('"' + list.mobile + '"', '')
              .replace(',,', ',')
              .replace('[,', '[')
              .replace(',]', ']'));
          } else {
            fields = R.intersection(fields, JSON.parse(list.fields));
          }
        }
      }
    }
    campaign.total_contact = totalContact;
    return fields;
  }


  GetStops(): Observable<any>  {
    const url = environment.apiUrl + '/variables/stops';
    return this.httpClient.get(url);
  }

  AdminGetAll(adminid): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/admin';
    return this.httpClient.get(url);
  }

  GetAll(clientid, status): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/user/' + clientid + '/' + status;
    return this.httpClient.get(url).pipe(
      map( (res: any) => {
        // console.log(res);
        for (const c of res) {
          c.type =  (c.asap === '1' ? 'Immédiate' : ( c.asap === '0' ? 'Planifiée le ' + c.datebegin : 'Séquencée' ));
        }
        return res;
      })
    );
  }

  SearchById(clientid, status, campagneId): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/list/' + clientid + '/' + status + '/' + campagneId;
    return this.httpClient.get(url).pipe(
      map( (res: any) => {
        // console.log(res);
        for (const c of res) {
          c.type =  (c.asap === '1' ? 'Immédiate' : ( c.asap === '0' ? 'Planifiée le ' + c.datebegin : 'Séquencée' ));
        }
        return res;
      })
    );
  }

  Add(clientid, campaign): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/' + clientid;
    return this.httpClient.post(url, campaign);
  }

  Update(clientid, campaign): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/' + clientid + '/' + campaign.id + '/update';
    return this.httpClient.put(url, campaign);
  }

  Delete(clientid, campaignid): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/' + clientid + '/' + campaignid;
    return this.httpClient.delete(url);
  }

  AddList(clientid, campaignid, list): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/' + clientid + '/' + campaignid + '/addlist';
    return this.httpClient.put(url, list);
  }

  RemoveList(clientid, campaignid, listids): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/' + clientid + '/' + campaignid + '/removelist';
    return this.httpClient.put(url, listids);
  }

  Test(clientid, campaignid, contact): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/' + clientid + '/' + campaignid + '/test';
    return this.httpClient.put(url, contact);
  }

  Start(clientid, campaignid): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/' + clientid + '/' + campaignid + '/start';
    return this.httpClient.get(url);
  }

  StartForContact(clientid, campaignid, contactid): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/' + clientid + '/' + campaignid + '/startforcontact/' + contactid;
    return this.httpClient.get(url);
  }

  Pause(clientid, campaignid): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/' + clientid + '/' + campaignid + '/pause';
    return this.httpClient.get(url);
  }

  Resume(clientid, campaignid): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/' + clientid + '/' + campaignid + '/resume';
    return this.httpClient.get(url);
  }

  Cancel(clientid, campaignid): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/' + clientid + '/' + campaignid + '/cancel';
    return this.httpClient.get(url);
  }

  Archive(clientid, campaignid): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/' + clientid + '/' + campaignid + '/archive';
    return this.httpClient.get(url);
  }

  UnArchive(clientid, campaignid): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/' + clientid + '/' + campaignid + '/unarchive';
    return this.httpClient.get(url);
  }

  GetStatus(clientid, campaignid): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/' + clientid + '/' + campaignid + '/status';
    return this.httpClient.get(url);
  }

  GetHistory(clientid, campaignid): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/' + clientid + '/' + campaignid + '/details';
    return this.httpClient.get(url);
  }

  MailReport(clientid, campaignid): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/' + clientid + '/' + campaignid + '/mailreport';
    return this.httpClient.get(url);
  }

  MailReportAdmin(clientid, campaignid, adminid): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/' + clientid + '/' + campaignid + '/mailreportadmin/' + adminid;
    return this.httpClient.get(url);
  }

  MailRcvdMessages(clientid, campaignid): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/' + clientid + '/' + campaignid + '/mailrcvdmessages';
    return this.httpClient.get(url);
  }

  MailRcvdMessagesAdmin(clientid, campaignid, adminid): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/' + clientid + '/' + campaignid + '/mailrcvdmessagesadmin/' + adminid;
    return this.httpClient.get(url);
  }

  GetRcvdMessages(clientid, campaignid): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/' + clientid + '/' + campaignid + '/messages';
    return this.httpClient.get(url);
  }

}
