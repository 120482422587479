<h3>Nouvelle formation:</h3>

@if (newFormation) {
<form name="form" role="form" autocomplete="off" autocapitalize="off" autocorrect="off"
  #f="ngForm">


  <div class="form-inline">
      <div class="col-5 btn btn-primary" style="margin-top:20px; margin-right:5px" [ngClass]="{'blink': newFormation.listid==0}">1 - Charger un fichier XLS ou CSV</div>

      <app-import-file [clientid]="client?.id" (closeImport)="closeImport($event)" [isSmall]="true" style="width:50%; height: 80px;"></app-import-file>
  </div>
  <br>
  <br>
  <hr>
  <div class="form-group">
    <div class="col-12">
      <div class="form-group">
        <label class="h5 control-label" for="stagename">Nom:</label>
        <input type="text" name="stagename" id="stagename" class="form-control" [(ngModel)]="newFormation.name"
          autocomplete="off" autocapitalize="off" autocorrect="off" placeholder="Nom de la formation ou du stage"
          required #nameRef="ngModel" />
        @if (nameRef.dirty && nameRef.errors?.required) {
        <div class="text text-danger">Champ obligatoire</div>
        }
      </div>
    </div>
    <div class="form-check col-12">
      <div class="row">
        <div class="col-12 col-sm-4">
          <label class="h5" for="date">Date de début</label>
          <div class="input-group">
            <input class="form-control" placeholder="yyyy-mm-dd" name="datedebut" [(ngModel)]="datedebut" ngbDatepicker
              #d1="ngbDatepicker" (click)="d1.toggle()">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary btn-grey" (click)="d1.toggle()" type="button"><i
                  class="fa fa-calendar"></i></button>
            </div>
          </div>
        </div>
        <div class="col-6 col-sm-4">
          <label class="h5" for="stage">Stage</label>
          <div class="input-group">
            <input type="text" class="form-control" id="stage" name="stage" [(ngModel)]="newFormation.stage"
              autocomplete="off" autocapitalize="off" placeholder="Stage" required>
          </div>
        </div>
        <div class="col-6 col-sm-4">
          <label class="h5" for="ville">Ville</label>
          <div class="input-group">
            <input type="text" class="form-control" id="ville" name="ville" [(ngModel)]="newFormation.ville"
              autocomplete="off" autocapitalize="off" placeholder="Ville" required>
          </div>
        </div>
      </div>
    </div>
    <div class="form-check col-12">
      <div class="row">
        <div class="col-8 col-sm-4">
          <label class="h5" for="dates">Dates complètes</label>
          <div class="input-group">
            <input type="text" class="form-control" id="dates" name="dates" [(ngModel)]="newFormation.dates"
              autocomplete="off" autocapitalize="off" placeholder="Dates complètes" required>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-actions d-flex">

    <button [disabled]="f.invalid || newFormation.listid == 0" class="btn btn-success" [ngClass]="{'btn-warning': f.invalid || newFormation.listid == 0, 'blink': !f.invalid && newFormation.listid != 0}"
    (click)="create()">2 - Créer la formation</button>

  </div>
</form>
}
<br>
<br>

<div class="clearfix">

  <h5>Liste des participants:</h5>
  <br>Nombre de stagiaires: {{stagiaires.length}}

  <table id="selectedColumn" class="table table-bordered table-striped table-hover table-sm" cellspacing="0"
    width="100%">
    <thead>
      <tr>
        <th class="th">ID</th>
        <th class="th">Nom</th>
        <th class="th">Prénom</th>
        <th class="th">N° Mobile</th>
        <th class="th">Email</th>
      </tr>
    </thead>
    <tbody>
      @for (s of stagiaires; track s) {
      <tr>
        <td>{{s.index}}</td>
        <td>{{s.nom}}</td>
        <td>{{s.prenom}}</td>
        <td>{{s.mobile}}</td>
        <td>{{s.email}}</td>
      </tr>
      }
    </tbody>
  </table>
</div>
