<ngx-spinner bdOpacity=0.2 bdColor="rgba(51,51,51,0)" size="medium" color="#515151" type="ball-pulse-sync"
  fullScreen="false">
</ngx-spinner>

<div class="card">
  <div class="card-body">

    <form class="form-inline">
      <div class="form-group">
        <button class="btn btn-success" (click)="Add()">
          <i class="fa fa-plus"></i>&nbsp;Ajouter un alias</button>
      </div>
    </form>
    <br>


    <div class="clearfix">
      <form class="inline">
        <!-- <button type="button" (click)="Export('TEXT')"
            class="btn btn-sm btn-outline-secondary ml-2 h-100">Copy</button>
          <button type="button" (click)="Export('CSV')" class="btn btn-sm btn-outline-secondary ml-2 h-100">CSV</button>
          <button type="button" (click)="Export('EXCEL')"
            class="btn btn-sm btn-outline-secondary ml-2 h-100">Excel</button>
          <button type="button" (click)="Export('PRINT')"
            class="btn btn-sm btn-outline-secondary ml-2 h-100">Print</button> -->

        <div class="form-group form-inline ml-auto">
          Recherche: <input class="form-control ml-2" type="text" name="searchTerm"
            [(ngModel)]="pagingService.searchTerm" />
        </div>
      </form>

      <table id="selectedColumn" class="table table-bordered table-striped table-hover table-sm" cellspacing="0"
        width="100%">
        <thead>
          <tr>
            <th class="th" sortable="id" (sort)="onSort($event)">ID</th>
            <th class="th collapsible" sortable="label" (sort)="onSort($event)">Alias</th>
            <th class="th collapsible" sortable="destination_list" (sort)="onSort($event)">Liste des numéros</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        @if (initialized) {
        <tbody>
          @for (alias of aliases$ | async; track alias) {
          <tr (click)="selectAlias(alias)" [ngClass]="{'selected': alias.id === selectedAlias?.id}">
            <td>{{alias.id}}</td>
            <td>
              <ngb-highlight [result]="alias.label" [term]="pagingService.searchTerm"></ngb-highlight>
            </td>
            <td class="collapsible" style="overflow-wrap: anywhere;">
              <ngb-highlight [result]="alias.destination_list" [term]="pagingService.searchTerm">
              </ngb-highlight>
            </td>
            <td>
              <div class="inline">
                <button type="button" title="Editer" (click)="Edit(alias)" class="btn btn-sm btn-primary ml-1 w30"><i
                    class="fa fa-edit"></i></button>
                <button type="button" title="Supprimer" (click)="Delete(alias)"
                  class="btn btn-sm btn-danger ml-1 w30"><i class="fa fa-trash-o"></i></button>
              </div>
            </td>
          </tr>
          }
        </tbody>
        }
      </table>

      <div class="inline">
        <div class="">
          Total: {{(total$ | async)}}
        </div>
        <div class="ml-auto">
          <ngb-pagination class="d-flex justify-content-end" [collectionSize]="total$ | async"
            [(page)]="pagingService.page" [boundaryLinks]="true" [rotate]="true" [directionLinks]="true"
            [pageSize]="pagingService.pageSize" [maxSize]="3" name="page">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>


<app-modal [(isActive)]="showDeleteConfirm" [overlayDismiss]="true" (overlayClick)="showDeleteConfirm=false">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Suppression de l'alias</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showDeleteConfirm=false">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Confirmez-vous la suppression de cet alias ?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)='DeleteConfirm()'>Confirmer</button>
      <button type="button" class="btn btn-secondary" data-dismiss="modal"
        (click)="showDeleteConfirm=false">Annuler</button>
    </div>
  </div>
</app-modal>


<app-modal [(isActive)]="showEditAlias" [overlayDismiss]="false" [isLarge]="true">
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{selectedAlias.id == 0 ? 'Ajout' : 'Edition'}} alias</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showEditAlias=false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form role="form" name="form">

          <div class="form-group">
            <label>Saisissez le numéro court de l'alias (4 chiffres)</label>
            <input type="text" name="label" class="form-control" [(ngModel)]="selectedAlias.label" autocomplete="off"
              autocapitalize="off" autocorrect="off" #labelRef="ngModel" [ngClass]="{ 'is-invalid': labelRef.invalid }"
              required maxlength="4" pattern= "[0-9]+"/>
            @if (labelRef.dirty && labelRef.errors?.required) {
            <span class="text-danger"> Saisissez un numeèro court</span>
            }
          </div>

          <div class="form-group">
            <label for="destination_list">Liste des numéros (séparés par un point-virgule)</label>
            <textarea name="destination_list" id="destination_list" class="form-control" style="resize:vertical;margin-bottom:10px;"
              rows="5" [(ngModel)]="selectedAlias.destination_list" pattern="([0-9];)+"></textarea>
          </div>
        </form>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)='UpdateAlias()'>{{ selectedAlias.id == 0 ? 'Ajouter' :
          'Modifier'}}</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal"
          (click)="showEditAlias=false">Annuler</button>
      </div>
    </div>
  </div>
</app-modal>
