<div class="client-view p-md-4">

  @if (!client) {
  <app-client-select [selectedUser]="client"></app-client-select>
  } @else {
  <div class="card">
    <div class="card-body">

      <h4>Historique des stages</h4>

      <ul class="nav nav-pills mb-n3" id="myTab" role="tablist">
        <li class="nav-item">
          <div class="nav-link" [ngClass]="{'active': activeTab===0}" id="tab0" data-toggle="tab" (click)="selectTab(0)"
            role="tab" aria-controls="tous" aria-selected="true">Tous les stages</div>
        </li>
        <li class="nav-item">
          <div class="nav-link" [ngClass]="{'active': activeTab===1}" id="tab1" data-toggle="tab" (click)="selectTab(1)"
            role="tab" aria-controls="details" aria-selected="false">Détails stage</div>
        </li>
        <li class="nav-item">
          <div class="nav-link" [ngClass]="{'active': activeTab===2}" id="tab2" data-toggle="tab" (click)="selectTab(2)"
            role="tab" aria-controls="nouveau" aria-selected="false">Nouveau stage</div>
        </li>
      </ul>
      <hr>

      <div class="tab-content" id="myTabContent">

        @if (activeTab === 0) {
        <div class="tab-pane fade show active" id="coord" role="tabpanel" aria-labelledby="coord-tab">

          <app-ade-list [client]='client' (formation)="selectFormation($event)">
          </app-ade-list>

        </div>
        }
      </div>

      <div class="tab-content" id="myTabContent">

        @if (activeTab === 1) {
        <div class="tab-pane fade show active" id="coord" role="tabpanel" aria-labelledby="coord-tab">

          <app-ade-details [client]='client' [formation]="selectedFormation" (editFormation)="editFormation($event)">
          </app-ade-details>

        </div>
        }
      </div>

      <div class="tab-content" id="myTabContent">

        @if (activeTab === 2) {
        <div class="tab-pane fade show active" id="coord" role="tabpanel" aria-labelledby="coord-tab">

          <app-ade-new [client]='client' (formation)="selectFormation($event)">
          </app-ade-new>

        </div>
        }
      </div>

      <div class="tab-content" id="myTabContent">

        @if (activeTab === 3) {
        <div class="tab-pane fade show active" id="coord" role="tabpanel" aria-labelledby="coord-tab">

          <app-ade-edit [client]='client' [selectedFormation]="selectedFormation" (formation)="selectFormation($event)">
          </app-ade-edit>

        </div>
        }
      </div>

    </div>
  </div>
  }
</div>
