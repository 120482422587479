import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/providers/authentication.service';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss'],
    standalone: true
})
export class LogoutComponent implements OnInit {

  constructor(
    private authService: AuthenticationService,
  ) { }

  ngOnInit() {
    this.authService.logout();
  }

}
