@if (authService.isAdmin) {
<div class="alert alert-secondary select-user">

  <div class="btn btn-info pull-right" style="margin-top:-9px" [routerLink]="['/client/selection']">Selectionner</div>
  @if (!selectedUser) {
  <div>Sélectionnez un client ...
  </div>
  } @else {
  <div>
    <div>
      Client sélectionné: <b>{{selectedUser.company}} {{selectedUser.firstname}} {{selectedUser.name}}</b> ({{selectedUser.id}})
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Crédits SMS: Premium <b>{{selectedUser.premium}}</b> / Lowcost
      <b>{{selectedUser.lowcost}}</b>
    </div>
  </div>
  }
</div>
}
