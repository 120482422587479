import { Component, Input, OnChanges, SimpleChanges, booleanAttribute } from '@angular/core';

import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-spinner',
  standalone: true,
  templateUrl: './spinner.component.html',
  styleUrl: './spinner.component.scss',
  imports: [
    NgxSpinnerModule,
  ],
})
export class SpinnerComponent implements OnChanges {

  @Input({transform: booleanAttribute}) visible = false;

  constructor(
    private spinner: NgxSpinnerService,
  ) {  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log("CHANGE spinner visible", changes.visible)
      if (changes.visible.currentValue) {
        this.spinner.show();
      } else {
        this.spinner.hide();
      }
  }
}
