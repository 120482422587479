<br>
<form>
  <div class="row">
    <div class="form-group col-md-3">
      <label for="startdate">Date de début de période</label>
      <div class="input-group">
        <input class="form-control" placeholder="yyyy-mm-dd" name="startdate" [(ngModel)]="filterParams.startdate"
          ngbDatepicker #d1="ngbDatepicker" (dateSelect)="checkDate()" (click)="d1.toggle()">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary btn-grey" (click)="d1.toggle()" type="button"><i
              class="fa fa-calendar"></i></button>
        </div>
      </div>
    </div>
    <div class="form-group col-md-3">
      <label for="stopdate">Date de fin</label>
      <div class="input-group">
        <input class="form-control" placeholder="yyyy-mm-dd" name="stopdate" [(ngModel)]="filterParams.stopdate"
          ngbDatepicker #d2="ngbDatepicker" (dateSelect)="checkDate()" (click)="d2.toggle()">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary btn-grey" (click)="d2.toggle()" type="button"><i
              class="fa fa-calendar"></i></button>
        </div>
      </div>
    </div>
    <div class="form-group col-md-3">
      <label for="nom_campagne">Filtre sur nom de campagne</label>
      <div class="input-group">
        <input name="nom_campagne" [(ngModel)]="filterParams.nom_campagne" class="form-control" type="text">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-md-3" >
      <label for="chefregion">Chef de région</label>
      <div class="input-group">
        <select name="chefregion" id="chefregion" class="form-control" [(ngModel)]="filterParams.chefid"
          (change)="checkDate()">
          @for (c of chefsRegion; track c) {
            <option [ngValue]="c.id">{{c.lastname}}</option>
          }
        </select>
      </div>
    </div>
    <div class="form-group col-md-3">
      <label for="startdate">Commercial</label>
      <div class="input-group">
        <select name="commercial" id="modele" class="form-control" [(ngModel)]="filterParams.commid"
          (change)="checkDate()">
          @for (c of commerciaux; track c) {
            <option [ngValue]="c.id">{{c.lastname}}</option>
          }
        </select>
      </div>
    </div>
    <div class="form-group col-md-3">
      <button class="btn" style="margin-top: 32px;"
        [ngClass]="{'btn-success blink':dateChanged, 'btn-info': !dateChanged}"
        (click)="applyFilters()">Rechercher</button>
    </div>
  </div>

</form>
<br>

<div class="clearfix">
  <form class="inline">
    <button type="button" (click)="Export('TEXT')" class="btn btn-sm btn-outline-secondary ml-2 h-100">Copy</button>
    <button type="button" (click)="Export('CSV')" class="btn btn-sm btn-outline-secondary ml-2 h-100">CSV</button>
    <button type="button" (click)="Export('EXCEL')" class="btn btn-sm btn-outline-secondary ml-2 h-100">Excel</button>
    <button type="button" (click)="Export('PRINT')" class="btn btn-sm btn-outline-secondary ml-2 h-100">Print</button>

    <div class="form-group form-inline ml-auto">
      Recherche: <input class="form-control ml-2" type="text" name="searchTerm"
        [(ngModel)]="pagingService.searchTerm" />
    </div>
  </form>

  <div class="btn btn-success center-btn" (click)="showSelected()"><i class="fa fa-list"></i>&nbsp;Voir tous les
    détails ({{selected.length}})</div>

  <table id="selectedColumn" class="table table-bordered table-striped table-hover table-sm" cellspacing="0"
    width="100%">
    <thead>
      <tr>
        <th class="th" sortable="id" (sort)="onSort($event)">ID</th>
        <th class="th" sortable="name" (sort)="onSort($event)">Campagne</th>
        <th class="th collapsible" sortable="datecreation" (sort)="onSort($event)">Date</th>
        <th class="th collapsible" sortable="nbclients" (sort)="onSort($event)">#clients</th>
        <th class="th collapsible" sortable="nbsms" (sort)="onSort($event)">#smsok</th>
        <th class="th collapsible" sortable="nbphotos" (sort)="onSort($event)">x</th>
        <th class="th" sortable="totalphotos" (sort)="onSort($event)">#photos</th>
        <th class="th collapsible">%</th>
        <th class="th"></th>
      </tr>
    </thead>
    @if (initialized) {
      <tbody>
      @for (c of campaigns$ | async; track c) {
      <tr>
        <td>{{c.id}}</td>
        <td>
          <ngb-highlight [result]="c.name" [term]="pagingService.searchTerm"></ngb-highlight>
        </td>
        <td class="collapsible">
          <ngb-highlight [result]="c.datecreation" [term]="pagingService.searchTerm">
          </ngb-highlight>
        </td>
        <td class="collapsible">
          <ngb-highlight [result]="c.nbclients" [term]="pagingService.searchTerm">
          </ngb-highlight>
        </td>
        <td class="collapsible">
          <ngb-highlight [result]="c.nbsms" [term]="pagingService.searchTerm">
          </ngb-highlight>
        </td>
        <td class="collapsible">
          <ngb-highlight [result]="c.nbphotos" [term]="pagingService.searchTerm">
          </ngb-highlight>
        </td>
        <td>
          <ngb-highlight [result]="c.totalphotos>0 ? c.totalphotos : ''" [term]="pagingService.searchTerm">
          </ngb-highlight>
        </td>
        <td class="collapsible">{{getPercentage(c)}}</td>
        <td>
          <div class="inline">
            <button type="button" title="Voir les détails" (click)="selectAndShowCampaign(c)"
              class="btn btn-sm btn-primary mr-2 w30"><i class="fa fa-list"></i></button>
            @if (c.selected) {
              <div class="btn btn-sm btn-success w30" (click)="ToggleSelectCampaign(c)"><i
                class="fa fa-check"></i></div>
            } @else {
              <div class="btn btn-sm btn-outline-secondary w30" (click)="ToggleSelectCampaign(c)"><i
                class="fa fa-minus"></i></div>
            }
          </div>
        </td>
      </tr>
      }
    </tbody>
    }
  </table>

  <div class="inline">
    <div class="">
      Total: {{(total$ | async)}}
    </div>
    <div class="ml-auto">
      <ngb-pagination class="d-flex justify-content-end" [collectionSize]="total$ | async" [(page)]="pagingService.page"
        [boundaryLinks]="true" [rotate]="true" [directionLinks]="true" [pageSize]="pagingService.pageSize" [maxSize]="3"
        name="page">
      </ngb-pagination>
    </div>
  </div>

</div>
