<div class="client-view p-md-4">

  <app-client-select [selectedUser]="client"></app-client-select>

<app-spinner [visible]="isSpinnerVisible"></app-spinner>

  <div class="card">
    <div class="card-body">

      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <div class="alert alert-success" role="alert">
            <h2>Credit Premium: {{client?.premium}}</h2>
          </div>

          @if (isAdmin) {
          <div class="row text-center">
            <div class="btn btn-success" (click)="AddPremium()">Achat Premium</div>
          </div>
}
        </div>
        <!-- <div class="col-sm-6 col-xs-12" *ngIf="client?.option_lowcost=='1'">
            <div class="alert alert-warning" role="alert">
              <h2>Credit Lowcost: {{client?.lowcost}}</h2>
            </div>

            <div *ngIf="isAdmin" class="row text-center">
              <div class="btn btn-warning" (click)="AddLowcost()">Achat Lowcost</div>
            </div>
          </div> -->
      </div>
      <br>

      <h3>Consommations mensuelles</h3>
      <div class="text-center canvas">
        <canvas id="canvas">{{ chart }}</canvas>
      </div>

      <div style="clear:both"></div>
      <br>
      <hr>

      <br>
      <h3>Historique</h3>

      <form>
        <div class="row">
          <div class="form-group col-4">
            <label for="startdate">Date de début de période</label>
            <div class="input-group">
              <input class="form-control" placeholder="yyyy-mm-dd" name="startdate" [(ngModel)]="period.startdate"
                ngbDatepicker #d1="ngbDatepicker" (dateSelect)="checkDate()" (click)="d1.toggle()">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary btn-grey" (click)="d1.toggle()" type="button"><i
                    class="fa fa-calendar"></i></button>
              </div>
            </div>
          </div>
          <div class="form-group col-4">
            <label for="stopdate">Date de fin</label>
            <div class="input-group">
              <input class="form-control" placeholder="yyyy-mm-dd" name="stopdate" [(ngModel)]="period.stopdate"
                ngbDatepicker #d2="ngbDatepicker" (dateSelect)="checkDate()" (click)="d2.toggle()">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary btn-grey" (click)="d2.toggle()" type="button"><i
                    class="fa fa-calendar"></i></button>
              </div>
            </div>
          </div>
          <div class="form-group col-4">
            <button class="btn" style="margin-top: 32px;"
              [ngClass]="{'btn-success blink':dateChanged, 'btn-info': !dateChanged}"
              (click)="selectPeriod()">Rechercher</button>
          </div>
        </div>

      </form>

      @if (isAdmin) {
<div style="margin-left:25px;font-size:16pw">
        <div class="row" style="margin-left:25px">
          <div class="col-xs-4 label label-success" style="width:80px">Premium</div>
          <div class="col-xs-4" style="width:200px">Consommés {{consoPeriod?.premium[0]}}</div>
          <div class="col-xs-4" style="width:200px">Achetés {{consoPeriod?.premium[1]}}</div>
        </div>
        <div class="row" style="margin-left:25px">
          <div class="col-xs-4 label label-warning" style="width:80px">Lowcost</div>
          <div class="col-xs-4" style="width:200px">Consommés {{consoPeriod?.lowcost[0]}}</div>
          <div class="col-xs-4" style="width:200px">Achetés {{consoPeriod?.lowcost[1]}}</div>
        </div>
      </div>
}

      <br>
      <br>

      <div class="p-20">


        <div class="clearfix">
          <form class="inline">
            <button type="button" (click)="Export('TEXT')"
              class="btn btn-sm btn-outline-secondary ml-2 h-100">Copy</button>
            <button type="button" (click)="Export('CSV')"
              class="btn btn-sm btn-outline-secondary ml-2 h-100">CSV</button>
            <button type="button" (click)="Export('EXCEL')"
              class="btn btn-sm btn-outline-secondary ml-2 h-100">Excel</button>
            <button type="button" (click)="Export('PRINT')"
              class="btn btn-sm btn-outline-secondary ml-2 h-100">Print</button>

            <div class="form-group form-inline ml-auto">
              Recherche: <input class="form-control ml-2" type="text" name="searchTerm"
                [(ngModel)]="pagingService.searchTerm" />
            </div>
          </form>

          <table id="selectedColumn" class="table table-bordered table-striped table-hover table-sm" cellspacing="0"
            width="100%">
            <thead>
              <tr>
                <th class="th" sortable="creditid" (sort)="onSort($event)">ID</th>
                <th class="th" sortable="campaignid" (sort)="onSort($event)">Campagne</th>
                <th class="th collapsible" sortable="label" (sort)="onSort($event)">Achat / Campagne</th>
                <th class="th" sortable="dateoperation" (sort)="onSort($event)">Date</th>
                <th class="th collapsible" sortable="test" (sort)="onSort($event)">Test</th>
                <th class="th" sortable="premium" (sort)="onSort($event)">Premium</th>
                <th class="th collapsible" sortable="lowcost" (sort)="onSort($event)">Lowcost</th>
              </tr>
            </thead>
            @if (initialized) {
<tbody>
              @for (credit of credits$ | async; track credit) {
<tr (click)="selectCredit(credit)"
                [ngClass]="{'selected': credit.creditid === selectedCredit?.creditid}">
                <td>{{credit.creditid}}</td>
                <td>
                  <ngb-highlight [result]="credit.campaignid" [term]="pagingService.searchTerm">
                  </ngb-highlight>
                </td>
                <td class="collapsible">
                  <ngb-highlight [result]="credit.label" [term]="pagingService.searchTerm">
                  </ngb-highlight>
                </td>
                <td>
                  <ngb-highlight [result]="credit.dateoperation" [term]="pagingService.searchTerm">
                  </ngb-highlight>
                </td>
                <td class="collapsible">
                  {{credit.test == 1 ? 'x' : ''}}
                </td>
                <td class="text-success"
                  [ngClass]="{'text-right text-danger': credit.campaignid>0 || credit.test == 1}">
                  {{credit.lowcost>=0 ? credit.premium : ''}}
                </td>
                <td class="collapsible text-success" [ngClass]="{'text-right text-danger': credit.campaignid>0}">
                  {{ (credit.lowcost<0) ? credit.lowcost : '' }}
                </td>
              </tr>
}
            </tbody>
}
          </table>

          <div class="inline">
            <div class="">
              Total: {{(total$ | async)}}
            </div>
            <div class="ml-auto">
              <ngb-pagination class="d-flex justify-content-end" [collectionSize]="total$ | async"
                [(page)]="pagingService.page" [boundaryLinks]="true" [rotate]="true" [directionLinks]="true"
                [pageSize]="pagingService.pageSize" [maxSize]="3" name="page">
              </ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>

<app-modal [(isActive)]="showAddCredit" [overlayDismiss]="true" [isLarge]="true" (overlayClick)="showAddCredit=false">
    <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Ajout crédits {{creditType == 1 ? 'Premium' : 'Lowcost'}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showAddCredit=false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form role="form" name="form">

          <div class="form-group">
            <label>Saisissez le nombre de crédits</label>
            <input type="number" name="credits" class="form-control" [(ngModel)]="credits"
              autocomplete="off" autocapitalize="off" autocorrect="off"
              required/>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)='AddCredits()'>Ajouter</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal"
          (click)="showAddCredit=false; credits=0">Annuler</button>
      </div>
    </div>
    </div>
  </app-modal>

