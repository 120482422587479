import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import { ClipboardService } from 'ngx-clipboard';
import { NgxSpinnerService } from 'ngx-spinner';
import moment from 'moment';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

const TEXT_TYPE = 'application/txt';
const TEXT_EXTENSION = '.csv';


@Injectable({
  providedIn: 'root'
})
export class ExportDataService {

  isSpinnerVisible = false;

  constructor(
    private clipboardService: ClipboardService,

  ) { }

  public async exportAs(kind: string, json: any[], excelFileName: string = null, images?: any, progress$?: any) {
    if (kind === 'CSV') {
      this.exportAsCsvFile(json, excelFileName);
    } else if (kind === 'EXCEL') {
      if (!images) {
        this.exportAsExcelFile(json, excelFileName, images);
      } else {
        await this.exportAsExcelFile2(excelFileName, json[0], json.slice(1), images, progress$)
      }
    } else if (kind === 'PRINT') {
      this.exportAsHtml(json);
    } else if (kind === 'TEXT') {
      this.exportAsText(json);
    }
  }


  public exportAsExcelFile(json: any[], excelFileName: string, images?: any): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, { skipHeader: true });
    // console.log('worksheet', worksheet);

    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }


  async resizeDataURL(datas, wantedWidth, wantedHeight) {

    return new Promise((resolve, reject) => {

        // We create an image to receive the Data URI
        var img = document.createElement('img');

        // When the event "onload" is triggered we can resize the image.
        img.onload = () => {
                // We create a canvas and get its context.
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                // We resize the image with the canvas method drawImage();
                ctx.drawImage(img, 0, 0, wantedWidth, wantedHeight);
                const dataURI = canvas.toDataURL();
                resolve(dataURI);
            };

        // We put the Data URI in the image's src attribute
        img.src = datas;
    });
  }


  async getBase64ImageFromUrl(imageUrl) {
    try {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener('load',  async () => {
          const resized = await this.resizeDataURL(reader.result, 280, 250);
          resolve(resized);
      }, false);

      reader.addEventListener('error',  async () => {
          resolve(null);
      }, false);

      reader.readAsDataURL(blob);

      setTimeout(() => {
        resolve(null);
      }, 5000);
    })
    } catch(e) {
      console.log('getBase64ImageFromUrl error', e);
      return null;
    }
  }


  public async exportAsExcelFile2(title, header, data, images, progress$)  {

    try {

      const processPhoto = async (photoName, index, col1, col2) => {
        try {
          if (images[row] && images[row][index].url) {
            //Add Image
            const base64 = await this.getBase64ImageFromUrl(images[row][index].url)
            if (base64) {
              let photo = workbook.addImage({
                base64, extension: 'png',
              });
              worksheet.addImage(photo, `${col1}${row + 2}:${col1}${row + 2}`);
              worksheet.getCell(`${col2}${row + 2}`).value = {
                text: photoName,
                hyperlink: images[row][index].url,
                tooltip: photoName
              };
              // console.log(photoName + ' done');
            } else {
              console.log(photoName + ' failed', images[row][index].url);
            }
          }
        } catch(e) {
          console.log('erreur pendant extraction image ', index + 1, e, images[row][index])
        }

      }



    console.log('export ExcelFile2', title, header, data, images)

    //Create a workbook with a worksheet
    let workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet(title);

    // THIS IS MLP specific !!!
    worksheet.getColumn(2).width = 12;
    worksheet.getColumn(3).width = 23;
    worksheet.getColumn(4).width = 23;
    worksheet.getColumn(5).width = 10;
    worksheet.getColumn(7).width = 23;
    worksheet.getColumn(8).width = 10;
    worksheet.getColumn(9).width = 10;
    worksheet.getColumn(10).width = 30;

    for(let photo=0; photo<10; photo++) {
      worksheet.getColumn(11 + photo * 2).width = 20;
      worksheet.getColumn(12 + photo * 2).width = 10;
    }


    //Adding Header Row
    const headerRow = worksheet.addRow(Object.values(header));
    worksheet.getRow(1).height = 25;
    // worksheet.getRow(1).font = {
      //   bold: true
      // };
      worksheet.getRow(1).border = {
        bottom: { style: 'double'}
      }

      //Blank Row
      // worksheet.addRow([]);


      // Adding Data with Conditional Formatting
      let row = 0;
      let max = data.length;
      for (const d of data) {

        if (progress$) {
          progress$.next((row + 1) + ' / ' + (max));
        }
        // console.log('export ROW', row, d);

        const values = Object.values(d);
        for(let col=0; col<values.length; col++) {
          worksheet.getCell(`${String.fromCharCode(65 + col)}${row + 2}`).value = values[col];
        }
        worksheet.getRow(row+2).height = 140;

        await processPhoto('photo1', 0, 'K', 'L');
        await processPhoto('photo2', 1, 'M', 'N');
        await processPhoto('photo3', 2, 'O', 'P');
        await processPhoto('photo4', 3, 'Q', 'R');
        await processPhoto('photo5', 4, 'S', 'T');
        await processPhoto('photo6', 5, 'U', 'V');
        await processPhoto('photo7', 6, 'W', 'X');
        await processPhoto('photo8', 7, 'Y', 'Z');
        await processPhoto('photo9', 8, 'AA', 'AB');
        await processPhoto('photo10', 9, 'AC', 'AD');

        row += 1;

      }

      //Generate & Save Excel File
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        this.saveAsExcelFile(blob, title );
      })

    } catch(e) {
      console.log('erreur pendant la generation Excel', e)
      this.isSpinnerVisible = false;
    }

  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    try {
    console.log('saveAsExcelFile 1')
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    console.log('saveAsExcelFile 2')
    FileSaver.saveAs(data, fileName + '_export_' + moment().format('YYYY-MM-DD') + EXCEL_EXTENSION);
    console.log('saveAsExcelFile 3')
    this.isSpinnerVisible = false;
    console.log('saveAsExcelFile 4')

    } catch (e) {
      console.log('saveAsExcelFile error', e)
    }
  }

  public exportAsCsvFile(json: any[], csvFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, { skipHeader: true });
    const csv = XLSX.utils.sheet_to_csv(worksheet);
    this.saveAsCsvFile(csv, csvFileName);
  }

  private saveAsCsvFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: TEXT_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + moment().format('YYYY-MM-DD') + TEXT_EXTENSION);
    this.isSpinnerVisible = false;
  }

  public exportAsHtml(json: any[]): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, { skipHeader: true });
    const printContent = XLSX.utils.sheet_to_html(worksheet);
    // .replace('<table>', '<table table border="1" cellpadding="0" cellspacing="0" style="border-collapse:collapse;">');

    // console.log(printContent);

    const windowUrl = 'about:blank';
    const windowName = 'Print' + moment().format('YYYY-MM-DD');
    const printWindow = window.open(windowUrl, windowName, 'left=50000,top=50000,width=0,height=0');

    printWindow.document.write(printContent);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
    this.isSpinnerVisible = false;
  }

  public exportAsText(json: any[]): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, { skipHeader: true });
    const txtContent = XLSX.utils.sheet_to_csv(worksheet, { FS: '\t'});
    this.clipboardService.copyFromContent(txtContent);
    this.isSpinnerVisible = false;
  }


  // toUTF8Array(str) {
  //   const utf8 = [];
  //   for (let i = 0; i < str.length; i++) {
  //       let charcode = str.charCodeAt(i);
  //       if (charcode < 0x80) {
  //         utf8.push(charcode);
  //       } else if (charcode < 0x800) {
  //         // tslint:disable-next-line:no-bitwise
  //         utf8.push(0xc0 | (charcode >> 6), 0x80 | (charcode & 0x3f));
  //       } else if (charcode < 0xd800 || charcode >= 0xe000) {
  //         // tslint:disable-next-line:no-bitwise
  //         utf8.push(0xe0 | (charcode >> 12), 0x80 | ((charcode>>6) & 0x3f), 0x80 | (charcode & 0x3f));
  //       } else {
  //         // surrogate pair
  //           i++;
  //           // UTF-16 encodes 0x10000-0x10FFFF by
  //           // subtracting 0x10000 and splitting the
  //           // 20 bits of 0x0-0xFFFFF into two halves
  //           // tslint:disable-next-line:no-bitwise
  //           charcode = 0x10000 + (((charcode & 0x3ff) << 10) | (str.charCodeAt(i) & 0x3ff));
  //           // tslint:disable-next-line:no-bitwise
  //           utf8.push(0xf0 | (charcode >> 18), 0x80 | ((charcode >> 12) & 0x3f), 0x80 | ((charcode >> 6) & 0x3f), 0x80 | (charcode & 0x3f));
  //       }
  //   }
  //   return utf8.toString();
  // }
}
