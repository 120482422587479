<app-menu-header></app-menu-header>

<div class="main-container">

    <div class="main-sms alert alert-well" >
        <div class="btn btn-info pull-right"  style="margin-top:-9px" ng-click="changeTab(tabs.client)">Selectionner</div>
        @if (client?.id==0) {
<div>Sélectionnez un client ...
        </div>
}
        @if (client?.id>0) {
<div>
            <div>
            Client sélectionné: <b>{{client?.company}} {{client?.firstname}} {{client?.name}}</b> ({{client.id}})
             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Crédits SMS: Premium <b>{{client?.premium}}</b> / Lowcost <b>{{client?.lowcost}}</b>
             </div>
        </div>
}
    </div>

    <router-outlet></router-outlet>

</div>
