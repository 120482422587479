import { ViewChildren, QueryList, Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { Observable, ReplaySubject, of } from 'rxjs';
import { SortEvent, NgbdSortableHeader } from 'src/app/directives/sortable.directive';
import { environment } from '../../../environments/environment';

import { PagingService } from '../../providers/paging.service';
import { UserService } from 'src/app/providers/user.service';
import { Router } from '@angular/router';

import * as R from 'ramda';
import { ExportDataService } from 'src/app/providers/export-data.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbHighlight, NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { IFacture } from 'src/app/interfaces/facture';
import { IUser } from 'src/app/interfaces/user';
import { DocumentService } from 'src/app/providers/document.service';
import { AuthenticationService } from 'src/app/providers/authentication.service';
import { ModalComponent } from '../../modals/modal.component';
import { NgbdSortableHeader as NgbdSortableHeader_1 } from '../../directives/sortable.directive';
import { FormsModule } from '@angular/forms';
import { NgClass, AsyncPipe } from '@angular/common';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
    selector: 'app-facture',
    templateUrl: './facture.component.html',
    styleUrls: ['./facture.component.scss'],
    standalone: true,
    imports: [SpinnerComponent, FormsModule, NgbdSortableHeader_1, NgClass, NgbHighlight, NgbPagination, ModalComponent, AsyncPipe]
})
export class FactureComponent implements OnInit {

  factures$: Observable<IFacture[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;

  users: IUser[] = [];
  selectedFacture: IFacture = null;

  @Input() client: IUser;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  page = 1;
  pageSize = 10;
  collectionSize = 1;
  searchFields = ['id', 'name', 'datecreation'];
  isAdmin = this.authService.isAdmin;

  showDeleteConfirm = false;
  initialized = false;
  isSpinnerVisible = false;

  constructor(
    public pagingService: PagingService<IFacture>,
    private authService: AuthenticationService,
    private documentService: DocumentService,
    private toastr: ToastrService,
  ) {
    this.factures$ = pagingService.rowsObs;
    this.total$ = pagingService.totalObs;
    this.loading$ = pagingService.loadingObs;
    this.pagingService.searchTerm = '';
    // set fields for filtering
    this.pagingService.fields = this.searchFields;


  }

  ngOnInit(): void {

    console.log('factures onInit', this.client);
    this.pagingService.loadRows(of([]));

    if (this.client) {
      this.pagingService.loadRows(this.documentService.GetAll(this.client.id));
      this.loading$.subscribe(loading => {
        if (loading) {
          this.isSpinnerVisible = true;
        } else {
          this.isSpinnerVisible = false;
          this.initialized = true;
        }
      });
      this.pagingService.page = 1;
    }
  }

  pageChanged(event) {
    console.log('onPageChange', event);
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.pagingService.sortColumn = column;
    this.pagingService.sortDirection = direction;
  }

  selectFacture(facture) {
    this.selectedFacture = R.clone(facture);
  }

  Upload() {
    if (!this.client || this.client.id === 0) { return; }
  }

  Delete(facture) {
    this.selectedFacture = facture;
    this.showDeleteConfirm = true;
    console.log('show delete confirm');
  }

  DeleteConfirm() {
    this.showDeleteConfirm = false;
    console.log('call delete api');
    this.isSpinnerVisible = true;

    this.documentService.Delete(this.client.id, this.selectedFacture.id).subscribe((response: any) => {
      if (response.success) {
        this.toastr.success('Le document a été supprimé', '');
        this.pagingService.loadRows(this.documentService.GetAll(this.client.id));
      } else {
        this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
      }
      this.isSpinnerVisible = false;
    });
  }

  View(facture) {
    const url = environment.apiUrl;
    window.open(
      url + '/show_pdf.php?id=' + this.client.id + '&docid=' + facture.id,
      '',
      'toolbar=no, menubar=no,scrollbars=yes,resizable=yes,location=no,directories=no,status=no, width=1000, height=700');

  }

  onFilesAdded(files: File[]) {
    console.log(files);
    this.isSpinnerVisible = true;

    if (files.length > 0) {
      const file = files[0];
      const reader = new FileReader();

      reader.onload = (event: any) => {
        const content = event.target.result;
        const data = { name: file.name, size: file.size, data: content};

        // this content string could be used directly as an image source
        // or be uploaded to a webserver via HTTP request.
        this.documentService.Add(this.client.id, data).subscribe( (response: any) => {
          if (response.success) {
            this.toastr.success('Le document a été chargé', '');
            this.pagingService.loadRows(this.documentService.GetAll(this.client.id));
          } else {
            this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
          }
          this.isSpinnerVisible = false;
        });
      };

      // for PDF use binary format
      reader.readAsBinaryString(file);
    }
  }

  Email(facture) {
    this.isSpinnerVisible = true;
    this.documentService.Mail(this.client.id, facture.id).subscribe((response: any) => {
      if (response.success) {
        this.toastr.success('Le document a été envoyé à l\'adresse ' + this.client.username, '');
      } else {
        this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
      }
      this.isSpinnerVisible = false;
    });

  }

}
