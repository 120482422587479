<app-spinner [visible]="isSpinnerVisible"></app-spinner>

@if (selectedCampaigns.length==0) {
<div>
  <div class="alert alert-danger w-100">Séléctionnez en premier une campagne dans la liste 'Toutes les campagnes'</div>
</div>
}

<br>
@if (selectedCampaigns.length>0) {
<div class="alert alert-well camp-info">
  <h3>{{selectedCampaigns[0].name}}</h3>
  <p>{{selectedCampaigns[0].datecreation}}</p>
</div>
}
@if (selectedCampaigns.length > 1) {
<div class="camp-info">
  <h3>Campagnes du {{filterParams.startdate | date: 'dd/MM/yyyy' }} au {{filterParams.stopdate | date: 'dd/MM/yyyy'}}
  </h3>
  <div>{{selectedCampaigns.length}} campagnes sélectionnées</div>
  @for (c of selectedCampaigns; track c) {
  <div>
    {{c.id}} - {{c.name}}
  </div>
  }
</div>
}
<br>

@if (selectedCampaigns.length>0) {
<div class="clearfix">
  <form class="inline">
    <button type="button" (click)="Export('TEXT')" class="btn btn-sm btn-outline-secondary ml-2 h-100">Copy</button>
    <button type="button" (click)="Export('CSV')" class="btn btn-sm btn-outline-secondary ml-2 h-100">CSV</button>
    <button type="button" (click)="Export('EXCEL')" class="btn btn-sm btn-outline-secondary ml-2 h-100">Excel</button>
    <button type="button" (click)="Export('PRINT')" class="btn btn-sm btn-outline-secondary ml-2 h-100">Print</button>

    <div class="progress">{{progress$ | async}}</div>

    <div class="form-group form-inline ml-auto">
      Recherche: <input class="form-control ml-2" type="text" name="searchTerm"
        [(ngModel)]="pagingService.searchTerm" />
    </div>
  </form>

  <table id="selectedColumn" class="table table-bordered table-striped table-hover table-sm" cellspacing="0"
    width="100%">
    <thead>
      <tr>
        <th class="th" sortable="id" (sort)="onSort($event)">ID</th>
        <th class="th" sortable="campaignid" (sort)="onSort($event)">#Campagne</th>
        <th class="th collapsible" sortable="chef_region" (sort)="onSort($event)">ChefDeRégion</th>
        <th class="th collapsible" sortable="commercial" (sort)="onSort($event)">Commercial</th>
        <th class="th collapsible" sortable="telephone" (sort)="onSort($event)">Telephone</th>
        <th class="th" sortable="nim" (sort)="onSort($event)">NIM</th>
        <th class="th collapsible" sortable="client" (sort)="onSort($event)">Nom</th>
        <th class="th collapsible" sortable="smsok" (sort)="onSort($event)">SMS ok</th>
        <th class="th collapsible">#Photos</th>
        <th class="th collapsible" sortable="commentaires" (sort)="onSort($event)">Commentaires</th>
        <th class="th"></th>
      </tr>
    </thead>
    @if (initialized) {
    <tbody>
      @for (c of campaigns$ | async; track c) {
      <tr>
        <td>
          <ngb-highlight [result]="c.id" [term]="pagingService.searchTerm"></ngb-highlight>
        </td>
        <td>
          <ngb-highlight [result]="c.campaignid" [term]="pagingService.searchTerm"></ngb-highlight>
        </td>
        <td class="collapsible">
          <ngb-highlight [result]="c.chef_region" [term]="pagingService.searchTerm">
          </ngb-highlight>
        </td>
        <td class="collapsible">
          <ngb-highlight [result]="c.commercial" [term]="pagingService.searchTerm">
          </ngb-highlight>
        </td>
        <td class="collapsible">
          <ngb-highlight [result]="c.telephone" [term]="pagingService.searchTerm">
          </ngb-highlight>
        </td>
        <td class="">
          <ngb-highlight [result]="c.nim" [term]="pagingService.searchTerm">
          </ngb-highlight>
        </td>
        <td class="collapsible">
          <ngb-highlight [result]="c.client" [term]="pagingService.searchTerm">
          </ngb-highlight>
        </td>
        <td class="collapsible">
          <ngb-highlight [result]="c.smsok" [term]="pagingService.searchTerm">
          </ngb-highlight>
        </td>
        <td class="collapsible">{{c.totalphotos > 0 ? c.totalphotos : ''}}</td>
        <td class="collapsible">
          <ngb-highlight [result]="c.commentaires" [term]="pagingService.searchTerm">
          </ngb-highlight>
        </td>
        <td>
          <div class="inline">
            <button type="button" title="Voir les photos" (click)="showPhoto(c)"
              class="btn btn-sm btn-success mr-2 w30"><i class="fa fa-camera"></i></button>
            <button type="button" title="Voir les stats" (click)="showCusto(c.nim)"
              class="btn btn-sm btn-primary mr-2 w30"><i class="fa fa-user"></i></button>
          </div>
        </td>
      </tr>
      }
    </tbody>
    }
  </table>

  <div class="inline">
    <div class="">
      Total: {{(total$ | async)}}
    </div>
    <div class="ml-auto">
      <ngb-pagination class="d-flex justify-content-end" [collectionSize]="total$ | async" [(page)]="pagingService.page"
        [boundaryLinks]="true" [rotate]="true" [directionLinks]="true" [pageSize]="pagingService.pageSize" [maxSize]="3"
        name="page">
      </ngb-pagination>
    </div>
  </div>
</div>
}
