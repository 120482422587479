import {Injectable, PipeTransform} from '@angular/core';

import {BehaviorSubject, Observable, of, Subject} from 'rxjs';

import {DecimalPipe} from '@angular/common';
import {debounceTime, delay, switchMap, tap} from 'rxjs/operators';
import {SortDirection} from '../directives/sortable.directive';

import * as R from 'ramda';

interface SearchResult<T> {
  rows: T[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: string;
  sortDirection: SortDirection;
  fields: string[];
}

function compare(v1, v2) {
  // console.log('compare', v1, v2);
  if (!Number.isInteger(parseInt(v1, 10)) || ( typeof v1 === 'string' &&  v1.indexOf(':') !== -1)) {
    // console.log('string', v1 < v2 ? -1 : v1 > v2 ? 1 : 0);
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  } else {
    const i1 = parseInt(v1, 10);
    const i2 = parseInt(v2, 10);
    // console.log('int', i1 < i2 ? -1 : i1 > i2 ? 1 : 0);
    return i1 < i2 ? -1 : i1 > i2 ? 1 : 0;
  }
}

function sort<T>(rows: T[], column: string, direction: string): T[] {
    if (direction === '') {
    return rows;
  } else {
    return [...rows].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches<T>(row: T, fields: string[], term: string, pipe: PipeTransform) {

  for (const f of fields) {
    if (row[f]) {
      const val = row[f].toString();
      if (val.toLowerCase().includes(term)) { return true; }
    }
  }
  return false;
}

@Injectable({providedIn: 'root'})
export class PagingService<T> {

  private loading$ = new BehaviorSubject<boolean>(true);
  private search$ = new Subject<void>();
  private rows$ = new BehaviorSubject<T[]>([]);
  private total$ = new BehaviorSubject<number>(0);

  private state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: '',
    fields: [],
  };

  allRows: T[] = [];
  filteredRows: T[] = [];

  constructor(
    private pipe?: DecimalPipe,
  ) {
  }

  loadRows(service) {

    if (service) {
      service.subscribe(datas => {
        if (datas) {
          this.allRows = R.flatten(datas);
          this.total$.next(this.allRows.length);
          this.search$.pipe(
            tap(() => this.loading$.next(true)),
            debounceTime(300),
            switchMap(() => this._search()),
            delay(1),
            tap(() => this.loading$.next(false))
            ).subscribe(result => {
              this.rows$.next(result.rows);
              this.total$.next(result.total);
            });
          this.search$.next();
        }
      });
    }
  }

  get rowsObs() { return this.rows$.asObservable(); }
  get totalObs() { return this.total$.asObservable(); }
  get loadingObs() { return this.loading$.asObservable(); }

  get page() { return this.state.page; }
  set page(page: number) { this._set({page}); }

  get fields() { return this.state.fields; }
  set fields(fields: string[]) { this._set({fields}); }

  get pageSize() { return this.state.pageSize; }
  set pageSize(pageSize: number) { this._set({pageSize}); }

  get searchTerm() { return this.state.searchTerm; }
  set searchTerm(searchTerm: string) { this._set({searchTerm: searchTerm.toLowerCase()}); }

  set sortColumn(sortColumn: string) { this._set({sortColumn}); }
  set sortDirection(sortDirection: SortDirection) { this._set({sortDirection}); }

  private _set(patch: Partial<State>) {
    Object.assign(this.state, patch);
    this.search$.next();
  }

  private _search(): Observable<SearchResult<T>> {
    const {sortColumn, sortDirection, pageSize, page, searchTerm, fields} = this.state;

    // 1. sort
    let rows = sort(this.allRows, sortColumn, sortDirection);

    // 2. filter
    rows = rows.filter(row => matches(row, fields, searchTerm, this.pipe));
    const total = rows.length;

    // 3. store filtered/sorted users
    this.filteredRows = rows;

    // 3. paginate
    rows = rows.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({rows, total});
  }
}
