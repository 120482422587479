import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, zip } from 'rxjs';
import { map, combineLatest, mergeAll, toArray, flatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MlpService {

  constructor(
    private httpClient: HttpClient,
  ) {}

  GetMlpAllStat(clientid, period): Observable<any[]> {
    const url = environment.apiUrl + '/mlps/stathistory/' + clientid;
    return this.httpClient.post(url, period)
    .pipe(
      map((res: any) => {
        return res.stats;
      }),
    );
  }

  ImageRotate(args): Observable<any> {
    const url = environment.apiUrl + '/mlps/imagerotate';
    return this.httpClient.post(url, args);
  }

  GetMlpEmployee(): Observable<any> {
    const url = environment.apiUrl + '/mlps/employee';
    return this.httpClient.get(url);
  }

  GetMlpCustomer(): Observable<any> {
    const url = environment.apiUrl + '/mlps/customer';
    return this.httpClient.get(url);
  }

  GetMlpCampaign(campaignid): Observable<any[]> {

    console.log('GetMlpCampaign', campaignid);
    const url = environment.apiUrl + '/mlps/campaign/' + campaignid;
    return this.httpClient.get(url)
    .pipe(
      map((res: any) => {
        // console.log('res', res.mlp_camp);
        return res.mlp_camp;
      }),
    );
  }

  GetAllCampaign(campaigns): Observable<any[]> {
    console.log('GetAllCampaign');
    return zip(...campaigns.map( c => this.GetMlpCampaign(c.id)));
  }


  GetMlpStat(statid): Observable<any> {
    const url = environment.apiUrl + '/mlps/stat/' + statid;
    return this.httpClient.get(url);
  }

  GetMlpNim(nim, telephone): Observable<any> {
    const url = environment.apiUrl + '/mlps/nimhistory/' + nim + '/' + telephone;
    return this.httpClient.get(url).pipe(map( (res: any) => res.stats));
  }

  getNameFromId(employees, id) {
    const emp = employees.find( c => c.id === id);
    if (emp) {
      return emp.lastname;
    } else {
      return id;
    }
  }
}
