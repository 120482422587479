<div class="client-view p-md-4">

<app-spinner [visible]="isSpinnerVisible"></app-spinner>

  <div class="card">
    <div class="card-body">

      <span us-spinner="{radius:30, width:8, length: 16, position:'fixed', top:'30%'}" spinner-key="spinner-3"></span>

      @if (smspacks==null && initialized) {
        <div class="alert alert-danger">Les packs SMS n'ont pas été chargés correctement, merci de contacter votre administrateur !!</div>
      }

      <div class="row">

        <div class="col-md-6">
          Votre contact:
          <ul class="list-unstyled" style="margin-left:20px; font-size: 16px">
            <li style="font-size: 18px; font-weight: 500;"><i class="fa fa-envelope-o"></i>&nbsp;&nbsp;{{client?.adminusername}}</li>
            <li><i class="fa fa-phone"></i>&nbsp;&nbsp;{{client?.adminphone}}</li>
          </ul>
        </div>

        <div class="col-md-6">
          <h3 class="pull-right">Vous avez un code promo ?</h3>
          <div class="row pull-right m-1">
            <input type="text" class="input-small" [(ngModel)]="codepromo.tentative" placeholder="code promo"
              autocomplete="off" autocapitalize="off" autocorrect="off" (keyup.enter)="validateCodePromo()">
            <div class="btn btn-primary" (click)="validateCodePromo()">
              Valider
            </div>
          </div>
        </div>
      </div>

      <br>
      <br>

      <ul class="nav nav-pills mb-n3" id="myTab" role="tablist">
        <li class="nav-item">
          <div class="nav-link" [ngClass]="{'active': activeTab==='premium'}" id="premium-tab" data-toggle="tab"
            (click)="selectTab('premium')" role="tab" aria-controls="premium" aria-selected="true">SMS Premium</div>
        </li>
        @if (client?.option_lowcost==1) {
        <li class="nav-item">
          <div class="nav-link" [ngClass]="{'active': activeTab==='lowcost'}" id="lowcost-tab" data-toggle="tab"
            (click)="selectTab('lowcost')" role="tab" aria-controls="lowcost" aria-selected="false">SMS Lowcost</div>
        </li>
        }
      </ul>
      <hr>

      <div class="tab-content" id="myTabContent">

        @if (activeTab === 'premium') {
        <div class="tab-pane fade show active" id="premium" role="tabpanel" aria-labelledby="premium-tab">

          <h4 class="txt18 bold">Prix des SMS premium</h4>

          <div class="text-center">

            <form name="form" role="form" autocomplete="off" autocapitalize="off" autocorrect="off" form-autofill-fix>
              <!-- {{smspacks | json}} -->

              <table class="table table-bordered footable tablet no-paging footable-loaded" id="tableSender"
                style="width: 100%">
                <thead>
                  <tr>
                    <th>Nombre de SMS</th>
                    <th>Prix unitaire (HT)</th>
                    <th>Prix total (HT)</th>
                    <th>Prix total (TTC)</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  @for (p of smspacks?.premium; track p) {
                  <tr>
                    <td>
                      @if (p.custom==1) {
                      <div>
                        {{p.productname}}<br>
                        <input type="text" name="input1" class="input-small" name="custom" [(ngModel)]="p.qty" (ngModelChange)="recomputePrice(p.qty)" #pQty (click)="pQty.select()">
                        &nbsp;SMS&nbsp;
                      </div>
                      }
                      @if (p.custom==0) {
                        {{p.productname}}
                      }
                    </td>
                    <td>
                      <div><b>{{p.unitprice |number: '0.3-3'}}</b></div>
                    </td>
                    <td>
                      {{p.totalht | number: '1.2-2' }}
                    </td>
                    <td>
                      {{p.totalttc | number: '1.2-2'}}
                    </td>
                    <td>
                      @if (client.disabled === '0') {
                      <div class="btn btn-info btn-sm" (click)="selectPack(p)"><i
                          class="fa fa-cart"></i>&nbsp;&nbsp;Sélectionner
                      </div>
                      }
                    </td>
                  </tr>
                  }
                </tbody>
              </table>

            </form>

          </div>

        </div>
        }
      </div>

      @if (client?.option_lowcost==1) {
      <div class="tab-content" id="myTabContent">

        @if (activeTab === 'lowcost') {
        <div class="tab-pane fade show active" id="lowcost" role="tabpanel" aria-labelledby="lowcost-tab">

          <h4 class="txt18 bold">Prix des SMS lowcost</h4>

          <div class="text-center">

            <form name="form" role="form" autocomplete="off" autocapitalize="off" autocorrect="off" form-autofill-fix>
              <div class="section">

                <table class="table table-bordered footable tablet no-paging footable-loaded" id="tableSender"
                  style="width: 100%">
                  <thead>
                    <tr>
                      <th>Nombre de SMS</th>
                      <th>Prix unitaire (HT)</th>
                      <th>Prix total (HT)</th>
                      <th>Prix total (TTC)</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    @for (p of smspacks.lowcost; track p) {
                    <tr>
                      <td>
                        @if (p.custom==1) {
                        <div>
                          {{p.productname}}<br>
                          <input type="text" name="input1" class="input-small" name="custom" [(ngModel)]="p.qty" (ngModelChange)="recomputePrice(p.qty)" #lQty (click)="lQty.select()">
                          &nbsp;SMS&nbsp;
                        </div>
                        }
                        @if (p.custom==0) {
                          {{p.productname}}
                        }
                      </td>
                      <td>
                        <div><b>{{p.unitprice |number: '0.3-3'}}</b></div>
                      </td>
                      <td>
                        {{p.totalht | number: '1.2-2' }}
                      </td>
                      <td>
                        {{p.totalttc | number: '1.2-2'}}
                      </td>
                      <td>
                        @if (client.disabled === '0') {
                        <div class="btn btn-info btn-sm" (click)="selectPack(p)"><i
                            class="fa fa-cart"></i>&nbsp;&nbsp;Sélectionner</div>
                        }
                      </td>
                    </tr>
                    }
                  </tbody>
                </table>
              </div>
            </form>
          </div>

        </div>
        }

      </div>
      }
    </div>
  </div>
</div>

<app-modal [(isActive)]="showAchatSend" [overlayDismiss]="true" (overlayClick)="showAchatSend=false"  [isLarge]="true">
  <div class="modal-content">
    <div class="modal-header">
      <div class="center-header">
        <div class="siteicon"></div>
        <div>{{payment.qty}} {{payment.product}}</div>
      </div>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showPayment=false">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      Votre commande été transmise à votre commercial.<br>
      Celui-ci va vous recontacter dans les meilleurs délais pour finaliser votre achat.
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary pull-left" data-dismiss="modal"
        (click)="showAchatSend = false">OK</button>
    </div>

  </div>
</app-modal>


  <app-modal [(isActive)]="showPayment" [overlayDismiss]="true" (overlayClick)="showPayment=false"  [isLarge]="true">
    <div class="modal-content">
      <!-- <div class="icon"></div> -->
      <div class="modal-header">
        <div class="center-header">
          <div class="siteicon"></div>
          <div>{{payment.qty}} {{payment.product}}</div>
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showPayment=false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form novalidate>
        <div class="modal-body">
              <label for="username">Vote email:&nbsp;&nbsp;</label>
              <input type="text" [(ngModel)]="username"  placeholder="Votre email" name="username" id="username">

                <div class="creditcard">
                  <label>Votre carte bancaire:</label>
                  <!-- <ngx-stripe-card [options]="cardOptions" [elementsOptions] ="elementsOptions" (change)="cardUpdated($event)" (error)="error = $event"></ngx-stripe-card> -->
                  <div #payElement></div>
                  <!-- <div class="powered">Powered by <b>stripe</b></div> -->
                  <div class="error">
                    {{error?.message}}
                  </div>
                </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary btn-lg" [disabled]="!complete" (click)="doPayment()">
            Payer {{payment.totalttc | number: '1.2-2'}} €
          </button>
          <button type="button" class="btn btn-secondary pull-left" data-dismiss="modal"
            (click)="cancelPayment()">Annuler</button>
        </div>
      </form>
    </div>
  </app-modal>
