<app-spinner [visible]="isSpinnerVisible"></app-spinner>

<div class="p-20">

  <div class="row">
    <div class="btn btn-info mr-2" (click)="forfaitSendMail()">Envoyer le relevé des FORFAIT par mail</div>
  </div>
  <br>
  <br>

  <hr>
  <div class="container">
    <div class="row p-20">
      <div class="premium col-6">
        <h2>Premium: {{conso?.totalPremium}}</h2>
      </div>
    </div>
  </div>
  <br>
  <br>

  <div class="clearfix">
    <form class="inline">
      <button type="button" (click)="Export('TEXT')" class="btn btn-sm btn-outline-secondary ml-2 h-100">Copy</button>
      <button type="button" (click)="Export('CSV')" class="btn btn-sm btn-outline-secondary ml-2 h-100">CSV</button>
      <button type="button" (click)="Export('EXCEL')" class="btn btn-sm btn-outline-secondary ml-2 h-100">Excel</button>
      <button type="button" (click)="Export('PRINT')" class="btn btn-sm btn-outline-secondary ml-2 h-100">Print</button>

      <div class="form-group form-inline ml-auto">
        Recherche: <input class="form-control ml-2" type="text" name="searchTerm"
          [(ngModel)]="pagingService.searchTerm" />
      </div>
    </form>

    <table id="selectedColumn" class="table table-bordered table-striped table-hover table-sm" cellspacing="0"
      width="100%">
      <thead>
        <tr>
          <th class="th" sortable="id" (sort)="onSort($event)">ID</th>
          <th class="th" sortable="username" (sort)="onSort($event)">Email</th>
          <th class="th" sortable="company" (sort)="onSort($event)">Compagnie</th>
          <th class="th" sortable="forfait_quantity" (sort)="onSort($event)">Forfait</th>
          <th class="th" sortable="forfait_used" (sort)="onSort($event)">Utilisés</th>
          <th class="th" sortable="forfait_extra" (sort)="onSort($event)">Extra</th>
          <th class="th" sortable="forfait_total" (sort)="onSort($event)">Total</th>
          <th></th>
        </tr>
      </thead>
      @if (initialized) {
      <tbody>
        @for (u of users$ | async; track u) {
        <tr (click)="selectUser(u)" [ngClass]="{'selected': u.id === selectedUser?.id}">
          <td>{{u.id}}</td>
          <td>
            <ngb-highlight [result]="u.username" [term]="pagingService.searchTerm">
            </ngb-highlight>
          </td>
          <td class="collapsible">
            <ngb-highlight [result]="u.company" [term]="pagingService.searchTerm">
            </ngb-highlight>
          </td>
          <td>Quantité: {{u.forfait_quantity}}<br>du {{u.forfait_dayfisrt}} au {{u.forfait_daylast}}</td>
          <td>{{u.forfait_used}}</td>
          <td>{{u.forfait_extra}}</td>
          <td>{{u.forfait_total}}</td>
          <td>
            <div class="inline">
              <button type="button" title="Voir les campagnes" (click)="selectAndShowCampaigns(u)"
                class="btn btn-sm btn-success ml-1 w30"><i class="fa fa-play"></i></button>
              <button type="button" title="Voir les crédits" (click)="selectAndShowCredits(u)"
                class="btn btn-sm btn-info ml-1 w30"><i class="fa fa-info"></i></button>
            </div>
          </td>
        </tr>
        }
      </tbody>
      }
    </table>

    <div class="inline">
      <div class="">
        Total: {{(total$ | async)}}
      </div>
      <div class="ml-auto">
        <ngb-pagination class="d-flex justify-content-end" [collectionSize]="total$ | async"
          [(page)]="pagingService.page" [boundaryLinks]="true" [rotate]="true" [directionLinks]="true"
          [pageSize]="pagingService.pageSize" [maxSize]="3" name="page">
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>
