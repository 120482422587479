<app-spinner [visible]="isSpinnerVisible"></app-spinner>

<div class="card">
  <div class="card-body">

    <br>

    @if (isAdmin) {
<div class="form-inline" class="">

      <div class="dropzone-custom">
          <div class="dropzone-label">Glisser votre document ou cliquez ICI</div>
          <input type="file"
            accept="application/pdf" (change)="onFilesAdded($event.target.files)"
            multiple="false"
            mode="replace"
          />
      </div>

    <hr>
    </div>
}

    <br>
    <br>
    <h3>Liste de factures</h3>
    <br>



    <div class="clearfix">
      <form class="inline">
        <!-- <button type="button" (click)="Export('TEXT')"
            class="btn btn-sm btn-outline-secondary ml-2 h-100">Copy</button>
          <button type="button" (click)="Export('CSV')" class="btn btn-sm btn-outline-secondary ml-2 h-100">CSV</button>
          <button type="button" (click)="Export('EXCEL')"
            class="btn btn-sm btn-outline-secondary ml-2 h-100">Excel</button>
          <button type="button" (click)="Export('PRINT')"
            class="btn btn-sm btn-outline-secondary ml-2 h-100">Print</button> -->

        <div class="form-group form-inline ml-auto">
          Recherche: <input class="form-control ml-2" type="text" name="searchTerm"
            [(ngModel)]="pagingService.searchTerm" />
        </div>
      </form>

      <table id="selectedColumn" class="table table-bordered table-striped table-hover table-sm" cellspacing="0"
        width="100%">
        <thead>
          <tr>
            <th class="th" sortable="id" (sort)="onSort($event)">ID</th>
            <th class="th collapsible" sortable="name" (sort)="onSort($event)">Nom</th>
            <th class="th collapsible" sortable="datecreation" (sort)="onSort($event)">Date</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        @if (initialized) {
<tbody>
          @for (facture of factures$ | async; track facture) {
<tr (click)="selectFacture(facture)"
            [ngClass]="{'selected': facture.id === selectedFacture?.id}">
            <td>{{facture.id}}</td>
            <td>
              <ngb-highlight [result]="facture.name" [term]="pagingService.searchTerm"></ngb-highlight>
            </td>
            <td class="collapsible">
              <ngb-highlight [result]="facture.datecreation" [term]="pagingService.searchTerm">
              </ngb-highlight>
            </td>
            <td>
              <div class="inline">
                <button type="button" title="Visualiser" (click)="View(facture)"
                  class="btn btn-sm btn-primary ml-1 w30"><i class="fa fa-edit"></i></button>
                @if (isAdmin) {
<button type="button" title="Supprimer" (click)="Delete(facture)"
                  class="btn btn-sm btn-danger ml-1 w30">
                  <i class="fa fa-trash-o"></i></button>
}
                <button type="button" title="Email" (click)="Email(facture)" class="btn btn-sm btn-success ml-1 w30"><i
                    class="fa fa-envelope-o"></i></button>
              </div>
            </td>
          </tr>
}
        </tbody>
}
      </table>

      <div class="inline">
        <div class="">
          Total: {{(total$ | async)}}
        </div>
        <div class="ml-auto">
          <ngb-pagination class="d-flex justify-content-end" [collectionSize]="total$ | async"
            [(page)]="pagingService.page" [boundaryLinks]="true" [rotate]="true" [directionLinks]="true"
            [pageSize]="pagingService.pageSize" [maxSize]="3" name="page">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>


<app-modal [(isActive)]="showDeleteConfirm" [overlayDismiss]="true" (overlayClick)="showDeleteConfirm=false">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Suppression d'une facture'</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showDeleteConfirm=false">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Confirmez-vous la suppression de cette facture ?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)='DeleteConfirm()'>Confirmer</button>
      <button type="button" class="btn btn-secondary" data-dismiss="modal"
        (click)="showDeleteConfirm=false">Annuler</button>
    </div>
  </div>
</app-modal>
