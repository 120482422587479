import { ViewChildren, QueryList, Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { Observable, ReplaySubject, of } from 'rxjs';
import { SortEvent, NgbdSortableHeader } from 'src/app/directives/sortable.directive';
import { environment } from '../../../environments/environment';

import { PagingService } from '../../providers/paging.service';
import { AgenceService } from 'src/app/providers/agence.service';

import * as R from 'ramda';
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { IAgence, Agence } from 'src/app/interfaces/agence';
import { IUser } from 'src/app/interfaces/user';
import { ModalComponent } from '../../modals/modal.component';
import { NgbHighlight, NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { NgClass, AsyncPipe } from '@angular/common';
import { NgbdSortableHeader as NgbdSortableHeader_1 } from '../../directives/sortable.directive';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-agence',
    templateUrl: './agence.component.html',
    styleUrls: ['./agence.component.scss'],
    standalone: true,
    imports: [NgxSpinnerModule, FormsModule, NgbdSortableHeader_1, NgClass, NgbHighlight, NgbPagination, ModalComponent, AsyncPipe]
})
export class AgenceComponent implements OnInit {

  agences$: Observable<IAgence[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;

  selectedAgence: IAgence = new Agence(0, '', '', '', '', '');

  @Input() client: IUser;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  page = 1;
  pageSize = 10;
  collectionSize = 1;
  searchFields = ['id', 'code', 'contact', 'smstext', 'mobile'];

  showDeleteConfirm = false;
  showAddAgence = false;
  showEditAgence = false;

  initialized = false;
  isSpinnerVisible = false;

  constructor(
    public pagingService: PagingService<IAgence>,
    private agenceService: AgenceService,

    private toastr: ToastrService,
  ) {
    this.agences$ = pagingService.rowsObs;
    this.total$ = pagingService.totalObs;
    this.loading$ = pagingService.loadingObs;
    this.pagingService.searchTerm = '';
    // set fields for filtering
    this.pagingService.fields = this.searchFields;


  }

  ngOnInit(): void {

    console.log('agences onInit', this.client);
    this.pagingService.loadRows(of([]));
    if (this.client) {
      this.pagingService.loadRows(this.agenceService.GetAll(this.client.id));
      this.loading$.subscribe(loading => {
        if (loading) {
          this.isSpinnerVisible = true;
        } else {
          this.isSpinnerVisible = false;
          this.initialized = true;
        }
      });
      this.pagingService.page = 1;
    }
  }

  pageChanged(event) {
    console.log('onPageChange', event);
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.pagingService.sortColumn = column;
    this.pagingService.sortDirection = direction;
  }

  selectAgence(agence) {
    this.selectedAgence = R.clone(agence);
  }

  Edit(agence) {
    this.selectedAgence = R.clone(agence);
    this.showEditAgence = true;
  }

  Add() {
    if (!this.client || this.client.id === 0) { return; }
    // tslint:disable-next-line: quotemark
    this.selectedAgence = new Agence(this.client.id, '', '', '', '', "Bonjour,\nVous avez demandé des informations concernant l'annonce %reference%. Merci de cliquer sur le lien suivant pour obtenir les détails.\n%url%");
    this.showEditAgence = true;
  }

  UpdateAgence() {
    this.isSpinnerVisible = true;

    if (this.selectedAgence.id > 0) {
        this.agenceService.Update(this.client.id, this.selectedAgence)
        .subscribe((response: any) => {
            if (response.success) {
                this.toastr.success('L\'agence \'' + this.selectedAgence.code_agence + '\' a été modifiée');
                this.pagingService.loadRows(this.agenceService.GetAll(this.client.id));
            } else {
              this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
            }
            this.isSpinnerVisible = false;
        });
    } else {
      this.agenceService.Add(this.client.id, this.selectedAgence)
        .subscribe((response: any) => {
            if (response.success) {
              this.toastr.success('L\'agence \'' + this.selectedAgence.code_agence + '\' a été ajoutée');
              this.pagingService.loadRows(this.agenceService.GetAll(this.client.id));
            } else {
              this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
            }
            this.isSpinnerVisible = false;
        });
    }
    this.showEditAgence = false;
  }

  Delete(agence) {
    this.selectedAgence = agence;
    this.showDeleteConfirm = true;
    console.log('show delete confirm');
  }

  DeleteConfirm() {
    this.showDeleteConfirm = false;
    console.log('call delete api');
    this.isSpinnerVisible = true;

    this.agenceService.Delete(this.client.id, this.selectedAgence.id).subscribe((response: any) => {
      if (response.success) {
        this.toastr.success('L\'agence a été supprimée', '');
        this.pagingService.loadRows(this.agenceService.GetAll(this.client.id));
      } else {
        this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
      }
      this.isSpinnerVisible = false;
    });
  }

}
