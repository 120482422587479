<div class="client-view p-md-4">

<app-spinner [visible]="isSpinnerVisible"></app-spinner>

  <div class="card">
    <div class="card-body">

      <h3>Comptes Admin</h3>

      <form class="form-inline">
        <div class="form-group">
          <button class="btn btn-success" (click)="Add()">
            <i class="fa fa-plus"></i>&nbsp;Ajouter un admin</button>
        </div>
      </form>
      <br>


      <div class="clearfix">

        <table id="selectedColumn" class="table table-striped table-hover table-sm" cellspacing="0"
          width="100%">
          <thead>
            <tr>
              <th class="th">ID</th>
              <th class="th">Email</th>
              <th class="th">Telephone</th>
              <th class="th">Role</th>
              <th class="th">Super Admin</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            @for (adm of admins; track adm) {
<tr>
                <td>{{adm.id}}</td>
                <td>{{adm.username}}</td>
                <td>{{adm.phone}}</td>
                <td>{{adm.role}}</td>
                <td>{{adm.superadmin}}</td>
                <td>
                <div class="inline">
                  <button type="button" title="Editer" (click)="Edit(adm)"
                    class="btn btn-sm btn-primary ml-1 w30"><i class="fa fa-edit"></i></button>
                  <button type="button" title="Supprimer" (click)="Delete(adm)"
                    class="btn btn-sm btn-danger ml-1 w30"><i class="fa fa-trash-o"></i></button>
                </div>
              </td>
            </tr>
}
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<app-modal [(isActive)]="showDeleteConfirm" [overlayDismiss]="true" (overlayClick)="showDeleteConfirm=false">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Suppression Admin</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showDeleteConfirm=false">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Confirmez-vous la suppression de cet administrateur ?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)='DeleteConfirm()'>Confirmer</button>
      <button type="button" class="btn btn-secondary" data-dismiss="modal"
        (click)="showDeleteConfirm=false">Annuler</button>
    </div>
  </div>
</app-modal>


<app-modal [(isActive)]="showEditAdmin" [overlayDismiss]="false" [isLarge]="true">
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{selectedAdmin.id == 0 ? 'Ajout' : 'Edition'}} modèle</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showEditAdmin=false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="p-4">
        <form role="form" name="form">

          <div class="form-group">
            <label>Email</label>
            <input type="text" name="username" class="form-control" [(ngModel)]="selectedAdmin.username" autocomplete="off" autocapitalize="off" autocorrect="off" #usernameRef="ngModel" [ngClass]="{ 'is-invalid': usernameRef.invalid }"
              required />
            @if (usernameRef.dirty && usernameRef.errors?.required) {
<span class="text-danger">Saisissez un email</span>
}
          </div>

          <div class="form-group">
            <label>Telephone</label>
            <input type="text" name="phone" class="form-control" [(ngModel)]="selectedAdmin.phone" autocomplete="off" autocapitalize="off" autocorrect="off" #telRef="ngModel" [ngClass]="{ 'is-invalid': telRef.invalid }"
              required />
            @if (telRef.dirty && telRef.errors?.required) {
<span class="text-danger">Saisissez un telephone</span>
}
          </div>

          <div class="form-group">
            <div>Rôle</div>
            <select  [(ngModel)]="selectedAdmin.role" name="role">
              <option value="1" [selected]="selectedAdmin.role == 1">Administrateur</option>
              <option value="2" [selected]="selectedAdmin.role == 2">Super Admin</option>
            </select>
          </div>

          <div class="form-group">
            <div>Super-admin</div>
            <select name="admin" [(ngModel)]="selectedAdmin.superadmin" class="form-control">
              @for (superAdmin of admins; track superAdmin) {
<option [value]="superAdmin.id" [selected]="superAdmin.id === selectedAdmin.superadmin">
                {{superAdmin.id +' - '+ superAdmin.username}}</option>
}
            </select>
          </div>

        </form>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary"
          (click)='UpdateAdmin()'>{{ selectedAdmin.id == 0 ? 'Ajouter' : 'Modifier'}}</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal"
          (click)="showEditAdmin=false">Annuler</button>
      </div>
    </div>
  </div>
</app-modal>
